import { RenderableContent, SetState } from "../../../../types";
// Add to class future update
// import SelectInput from "../../../Components/selectInput/SelectInput";
// import SelectInputOption from "../../../Components/selectInput/components/SelectInputOption";
import { TeacherClass, UserInfo } from "../types";
import StudentBulkUpload from "./StudentBulkUpload";

import ExistingUser from "./exisitingUser";
import NewUser from "./newUser";
import MuiTabs from "../../../../Components/MuiTabs/MuiTabs";

// Add to class future update
// type teacherClassObj = {
//   _id: string;
//   name: string;
//   studentIds: string[];
// };

type Props = {
  setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>;
  setUsersInfo: SetState<UserInfo[]>;
  usersInfo: UserInfo[];
  // Add to class future update
  // teacherClasses: teacherClassObj[];
  // setTeacherClasses: SetState<TeacherClass[]>;
};

const AddUser = ({
  setOpenDialog,
  setUsersInfo,
  usersInfo,
}: // Add to class future update
// teacherClasses,
// Add to class future update
// setTeacherClasses,
Props) => {
  return (
    <MuiTabs
      tabs={[
        "Existing Student",
        "Single Student Upload",
        "Bulk Student Upload",
      ]}
      items={[
        <ExistingUser
          setOpenDialog={setOpenDialog}
          setUsersInfo={setUsersInfo}
          usersInfoId={usersInfo.map((user) => user.id.split("_").at(-1) ?? "")}
          // Add to class future update
          // teacherClasses={teacherClasses}
          // setTeacherClasses={setTeacherClasses}
        />,
        <NewUser
          setUsersInfo={setUsersInfo}
          setOpenDialog={setOpenDialog}
          // Add to class future update
          // teacherClasses={teacherClasses}
          // setTeacherClasses={setTeacherClasses}
        />,
        <StudentBulkUpload
          setOpenDialog={setOpenDialog}
          setUsersInfo={setUsersInfo}
        />,
      ]}
      centerTabs
    />
  );
};

export default AddUser;
