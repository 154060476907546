import IconButton from "@mui/material/IconButton";
import { S3_PUBLIC_IMAGES } from "../../config";


const images = {
  arrowLeft: `${S3_PUBLIC_IMAGES}/arrowLeft.png`,
  arrowRight: `${S3_PUBLIC_IMAGES}/arrowRight.png`
};

const wrapperStyle = {
  position: "relative", // Needed to position the buttons
  width: "100%",
  height: "100%",
};
const itemStyle = {
  flex: "0 0 auto", // This prevents the items from shrinking
  display: "flex", // Center the item content
  justifyContent: "center",
  alignItems: "center",
  padding: "15px",
};
const buttonStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  // backgroundColor: "rgba(255, 255, 255, 0.1)",
};

const Carousel = ({
  components = [],
  orientation = "horizontal",
  disableLeft = false,
  disableRight = false,
  index = 0,
  handleScroll,
  single = true,
  // scrollAmount = 100,
}) => {
  // const containerRef = useRef(null);
  // const [_scrollAmount, setScrollAmount] = useState(scrollAmount);
  // const [index, setIndex] = useState(startIndex);
  const displayComp = components.map((Component, index) => (
    <div
      key={index}
      style={{
        ...itemStyle,
        width: single ? "100%" : "auto",
        height: single ? "100%" : "auto",
      }}
    >
      <Component />
    </div>
  ));

  const containerStyle = {
    display: "flex",
    flexDirection: orientation,
    overflow: "hidden", // Hide the scrollbar
    width: "100%",
    height: "100%",
  };

  return (
    <div style={wrapperStyle}>
      {!disableLeft && (
        <IconButton
          style={{ ...buttonStyle, left: -50 }}
          onClick={() => handleScroll("left")}
        >
          <img src={images.arrowLeft} width="20" height="auto" alt="" />
          {/* <ArrowBackIosIcon /> */}
        </IconButton>
      )}

      <div style={containerStyle} > {/* ref={containerRef} */}
        {displayComp[index]}
      </div>

      {!disableRight && (
        <IconButton
          style={{ ...buttonStyle, right: -50 }}
          onClick={() => handleScroll("right")}
        >
          <img src={images.arrowRight} width='20' height='auto' alt="" />
          {/* <ArrowForwardIosIcon /> */}
        </IconButton>
      )}
    </div>
  );
};

export default Carousel;
