import { useContext } from "react";

import { MissionsTable } from "../../../Components/missionTable/missionsTable";
import ApiService from "../../../../Services/apiService";
import { InfoContext } from "../AdminCampaignsPage";
import { capitalizeWords } from "../../../../Util/utils";

import style from "../adminCampaignsPage.module.scss"

const noneParticipatingTableSetup = [
  { accessorKey: "fname", header: "First Name" },
  { accessorKey: "lname", header: "Last Name" },
  { accessorKey: "grade", header: "Grade" },
];

const AddStudentTable = ({ campaignId }) => {
  const { currentCampaign, gradeTableUpdater, setCurrentCampaign } =
    useContext(InfoContext);
  const apiService = new ApiService();

  function findObjectWithIndex(array, predicate) {
    const index = array.findIndex(predicate);
    if (index !== -1) {
      return { object: array[index], index: index };
    }
    return null; // or return undefined, or handle the case when the object is not found
  }

  const updateDom = (ids) => {
    setCurrentCampaign((prev) => {
      let goalType = capitalizeWords(prev.goalType);
      let newNoneParticipants = [...prev.noneParticipants];
      let newGradeTable = [...prev.gradeTable];
      let newParticipants = [...prev.participants];
      for (const id of ids) {
        const result = findObjectWithIndex(
          newNoneParticipants,
          (noneParticipant) => noneParticipant._id === id
        );
        const student = result.object;
        const index = result.index;
        if (index !== -1) {
          newNoneParticipants.splice(index, 1);
          newParticipants.push({
            ...student,
            rawData: {
              studentProgress: {
                value: 0,
                suffix: goalType,
              },
            },
          });
          newGradeTable = gradeTableUpdater(
            currentCampaign._id,
            goalType,
            student,
            newGradeTable,
            prev.gradeTotals
          );
        }
      }
      return {
        ...prev,
        noneParticipants: newNoneParticipants,
        participants: newParticipants,
        gradeTable: newGradeTable,
      };
    });
  };

  const addStudentHandler = (ids) => {
    const updateCampaignParticipants = async () => {
      const [campaignParticipantsResp, err] = await apiService.post({
        path: "/campaign_participants",
        data: { campaignId: campaignId, participantsIds: ids },
        dataType: "admin",
      });
    };
    updateCampaignParticipants();
    updateDom(ids);
  };

  const customBtn = {
    header: "",
    text: "Add",
    clickHandler: addStudentHandler,
  };

  return (
    <div className={style.addStudentTableContainer}>
      <MissionsTable
        title="Add Students"
        tableSetup={noneParticipatingTableSetup}
        data={currentCampaign.noneParticipants}
        customBtn={customBtn}
        tooltipSelected={customBtn}
        checkbox
      />
    </div>
  );
};

export default AddStudentTable;
