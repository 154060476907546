import React from 'react';
import { S3_PUBLIC_IMAGES } from '../../../config';
import { IconMenu2, IconUserFilled } from '@tabler/icons-react';
import { Button } from '../../../Components/button/button';
import { SideBar } from './components/sideBar/sideBar';
import styles from './header.module.scss';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

const mainLogo = `${S3_PUBLIC_IMAGES}/website/supermelogo.png`

export const Header = (props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const navigate = useNavigate();
  return (
    <nav
      className={clsx(styles.nav, {
        [styles.sticky]: props.sticky,
      })}
    >
      <div className={styles.logo}>
        <img src={mainLogo} onClick={() => navigate("/")} alt="Logo" />
        {props.menu && (
          <Button onClick={() => setSidebarOpen(true)} className={styles.hamburger}>
            <IconMenu2 />
          </Button>
        )}
        
      </div>
      <div className={styles.menu}>{!props.mobileOnly && props.menu}</div>
      {props.user && <IconUserFilled className={styles.profile} />}
      {props.menu && (
        <SideBar isOpen={sidebarOpen} setOpen={setSidebarOpen}>
          {props.menu}
        </SideBar>
      )}
    </nav>
  );
};

Header.defaultProps = {};