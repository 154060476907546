import { useCallback } from "react";
import { FileWithId } from "../Components/bulkUpload/types";

const useProcessCSVHook = () => {
  const CsvToListOfMaps = (str: string, delim = ",") => {
    let headers = str
      .slice(0, str.indexOf("\n"))
      .split(delim)
      .map((header) =>
        header
          .toLowerCase()
          .replace(/\(required\)|\(optional\)/g, "")
          .trim()
      );
    const last_header = headers[headers.length - 1];
    if (last_header.endsWith("\r"))
      headers[headers.length - 1] = last_header.split("\r")[0];
    let rows = str.slice(str.indexOf("\n") + 1).split(/\r?\n/);
    if (!rows[rows.length - 1]) rows.pop();

    const newArray = rows.map((row) => {
      const eachObject: Record<string, string> = {};
      for (const header of headers) {
        eachObject[header] = "";
      }
      let in_quotes = false;
      let h_i = 0;
      const values = row.split(delim);
      for (let i = 0; i < values.length; i++) {
        let value = values[i];
        const header = headers[h_i];
        if (value.startsWith('"')) {
          value = value.slice(value.indexOf('"') + 1);
          in_quotes = true;
        }
        if (value.endsWith('"')) {
          value = value.slice(0, value.indexOf('"'));
          in_quotes = false;
        }
        if (in_quotes) eachObject[header] += value.trim() + ",";
        else {
          eachObject[header] += value.trim();
          h_i++;
        }
      }
      return eachObject;
    });
    return newArray;
  };

  const processCSV = useCallback((csvFile: FileWithId) => {
    return new Promise<Record<string, string>[]>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (!e.target) {
          reject("Failed to read file.");
          return;
        }
        const text = e.target.result;
        if (typeof text === "string") {
          const result = CsvToListOfMaps(text);
          resolve(result);
        } else {
          reject("File format not supported.");
        }
      };
      reader.onerror = () => reject("Error reading file.");
      reader.readAsText(csvFile);
    });
  }, []);

  return processCSV;
};

export default useProcessCSVHook;
