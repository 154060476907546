import React, { PropsWithChildren } from "react";
import { useDrag } from "react-dnd";
import { ToolConfig, ToolConfigWithId } from "../game/game-config";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

export const Tool: React.FunctionComponent<PropsWithChildren<ToolProps>> =
  React.memo((props) => {
    const [{ opacity }, drag] = useDrag<
      ToolConfigWithId,
      any,
      { opacity: number }
    >({
      type: props.id,
      item: () => {
        if (props.setOpenInventory) {
          props.setOpenInventory(); // Call setOpenInventory when dragging starts
        }
        return { ...props };
      },
      canDrag: () => props.quantity > 0,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    });
    return (
      <div
        ref={drag}
        style={{ opacity }}
        className={clsx(
          "h-12 p-2 bg-brand/50 text-white rounded-md flex flex-col gap-2 justify-center items-center hover:scale-110 transition-all cursor-pointer select-none",
          {
            "grayscale cursor-not-allowed": props.quantity === 0,
          }
        )}
      >
        <div className="flex gap-2 items-center">
          <img
            src={props.image}
            alt={props.name}
            className="w-auto h-8 pointer-events-none"
          />
          <span>{props.quantity}</span>
        </div>
      </div>
    );
  });

interface ToolProps extends ToolConfigWithId {
  setOpenInventory?: () => void;
}

export const Tools: React.FunctionComponent<ToolsProps> = (props) => {
  const iconPos = props.direction === "left" ? "right-0" : "left-0";
  const hidden = props.hidden ? "d-none" : "";
  const positionTop =
    window.matchMedia("(orientation: landscape)").matches && isMobile
      ? "top-[64px]"
      : "top-[100px] md:top-[150px]";
  return (
    <div
      className={`absolute ${iconPos} p-4 flex flex-col gap-4 z-30 ${hidden} ${positionTop}`}
    >
      {Object.entries(props.tools).map(([key, value]) => (
        <Tool
          {...value}
          id={key}
          key={key}
          setOpenInventory={props.setOpenInventory}
        />
      ))}
    </div>
  );
};

interface ToolsProps {
  tools: Record<string, ToolConfig>;
  direction: string;
  setOpenInventory: () => void;
  hidden: boolean;
}
