import { RenderableContent, SetState } from "../../../../types";
import Dialog from "../../../Components/dialog/Dialog";

import styles from "../creatorPage.module.scss";
import { Data } from "../types";
import useNavigateDialog from "./useNavigateDialog";

type Props = {
  setDialogContent: SetState<RenderableContent>;
  currentStep: RenderableContent;
  handleContinueBtnClick: () => void;
  continueBtn: "Next" | "Create" | "Update";
  name: string;
  data: Data;
  setData: SetState<Data>;
  currentStepNum: number;
  setCurrentStepNum: SetState<number>;
  stepLength: number;
};

const useSetDialogHook = () => {
  const navigateDialog = useNavigateDialog();

  const setDialogHook = ({
    setDialogContent,
    currentStep,
    handleContinueBtnClick,
    continueBtn,
    name,
    data,
    setData,
    currentStepNum,
    setCurrentStepNum,
    stepLength,
  }: Props) => {
    const content = (
      <>
        {" "}
        {currentStep}
        <button
          className={`${styles.btn} btn ${styles.continueBtn}`}
          onClick={() => handleContinueBtnClick()}
        >
          {continueBtn}
        </button>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.btn} btn`}
            onClick={() =>
              navigateDialog(
                name,
                data,
                setData,
                currentStepNum,
                setCurrentStepNum,
                -1,
                stepLength
              )
            }
          >
            Back
          </button>
        </div>
      </>
    );
    setDialogContent(<Dialog content={content} />);
  };
  return setDialogHook;
};

export default useSetDialogHook;
