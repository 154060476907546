import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DialogSubText } from "../../dialogComponents/dialogComponents";

import { SetState } from "../../../../types";
import { FileWithId } from "../types";

import styles from "../bulkUpload.module.scss";
import { formatFileSize } from "../../../../Util/utilsT";

type Props = {
  file: FileWithId;
  setFiles: SetState<FileWithId[]>;
};

const UploadedFile = ({ file, setFiles }: Props) => {
  const deleteFileHandler = (id: string) => {
    setFiles((prev) => {
      const filteredFiles = prev.filter((f) => f.id !== id);
      return [...filteredFiles];
    });
  };

  return (
    <div className={styles.uploadedFiles}>
      <div className={styles.fileName}>
        <DialogSubText text={file.name} />
      </div>
      <div className={styles.size}>
        {" "}
        <DialogSubText text={formatFileSize(file.size)} />{" "}
      </div>
      <DoneIcon className={styles.progress} />
      <IconButton>
        <HighlightOffIcon
          className={styles.deleteFile}
          onClick={() => deleteFileHandler(file.id)}
        />
      </IconButton>
    </div>
  );
};

export default UploadedFile;
