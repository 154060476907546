import React from "react";
import { RenderableContent } from "../../types";
import { S3_PUBLIC_IMAGES } from "../../config";
import styles from "./printTemplate.module.scss";

type Props = {
  contentToPrint: RenderableContent;
};

const PrintTemplate = React.forwardRef(
  ({ contentToPrint }: Props, ref: any) => (
    <div className={styles.mainContainer} ref={ref}>
      <div className={styles.innerContainer}>
        <div className={styles.printLogoContainer}>
          {" "}
          <img
            className={styles.printLogoImg}
            src={`${S3_PUBLIC_IMAGES}/website/supermelogo.png`}
            alt=""
          />{" "}
        </div>
        <div>{contentToPrint}</div>
      </div>
    </div>
  )
);

export default PrintTemplate;
