import { useEffect, useRef, useState } from "react";
import cloudUploadIcon from "../../../assets/images/admin/cloudUploadIcon.png";
import {
  DialogBackBtn,
  DialogBtn,
  DialogMainText,
  DialogSubText,
} from "../dialogComponents/dialogComponents";
import UploadedFile from "./components/UploadedFile";

import { ExpectedHeaders, FileWithId, UploadSubmitDataKeys } from "./types";
import { fileTypesConstructor } from "../../../Util/utilsT";

import styles from "./bulkUpload.module.scss";
import useProcessUploads from "./hooks/useProcessUploads";
import { SetOpenDialog } from "../../Pages/UsersPage/types";
import useDisplayErrors from "./hooks/useDisplayErrors";
import { capitalizeWords } from "../../../Util/utils";
import useHandleDownloadHook from "../../../Hooks/useHandleDownloadHook";

type Props = {
  mainText: string;
  subText?: string;
  dragText: string;
  template: { fileToDownload: any; fileName: string };
  submitHandler: (processedUploads: Record<UploadSubmitDataKeys, any>) => void;
  backBtn?: () => void;
  acceptedFileTypes: ("csv" | "excel" | "pdf" | "img" | "gif")[];
  expectedHeaders: ExpectedHeaders;
  setOpenDialog: SetOpenDialog;
};

const BulkUpload = ({
  mainText,
  subText,
  dragText,
  template,
  submitHandler,
  backBtn,
  acceptedFileTypes,
  expectedHeaders,
  setOpenDialog,
}: Props) => {
  const handleDownloadHook = useHandleDownloadHook();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileWithId[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>();
  const processUploads = useProcessUploads({
    submitHandler,
    expectedHeaders,
    setOpenDialog,
  });
  const displayErrors = useDisplayErrors(setOpenDialog);
  useEffect(() => {
    console.log(files);
  }, [files]);

  const handleFileChange = (file: File) => {
    const acceptedFilesArray = fileTypesConstructor(acceptedFileTypes);
    if (!file) return;
    const fileWithId: FileWithId = Object.assign(file, {
      id: `${Date.now()}-${Math.random()}`,
    });

    if (acceptedFilesArray.includes(file.type)) {
      setFiles((prev) => [...prev, fileWithId]);
    } else {
      const accepted = acceptedFileTypes.join(", ");

      displayErrors([
        {
          fileName: file.name,
          error: [
            {
              errorTitle: `Invalid File Type: `,
              errorDetails: [
                `The file you uploaded is not supported. Please upload a file in one of the following formats: ${capitalizeWords(
                  accepted
                )}`,
              ],
            },
          ],
        },
      ]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileChange(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className={styles.mainContainer}>
      <DialogMainText text={mainText} />
      {subText && <DialogMainText text={subText} />}
      <div
        onClick={() => fileInputRef.current?.click()}
        className={isDragging ? `${styles.dragZone} dragZone` : styles.dragZone}
        onDragOver={handleDragOver}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={(e) =>
          e.target === e.currentTarget && setIsDragging(false)
        }
        onDrop={handleDrop}
      >
        <input
          className={`input ${styles.defualtFileUploadIcon}`}
          ref={fileInputRef}
          type="file"
          // id="csvFile"
          onChange={(e) =>
            e.target.files && handleFileChange(e.target.files[0])
          }
        />
        {/* Drag And Drop */}
        <div className={styles.flexContainer}>
          <img
            className={`${styles.cloudUploadIcon} dragZoneCloud`}
            src={cloudUploadIcon}
            alt="upload"
          />
        </div>
        <DialogSubText text={dragText} />
      </div>
      {files.length > 0 && (
        <>
          <DialogSubText text="Uploaded Files" />
          {files.map((file) => (
            <UploadedFile key={file.id} file={file} setFiles={setFiles} />
          ))}
        </>
      )}

      {/* {!props.no_toggle && "Update Existing?"}
    {!props.no_toggle && <Switch checked={props.checked} onChange={props.handleSwitchClick}/>} */}
      <div className={backBtn ? styles.gridContainer : styles.flexContainer}>
        {backBtn && (
          <div className={styles.backBtnContainer}>
            <DialogBackBtn action={() => backBtn()} />
          </div>
        )}
        <div className={styles.centeredDiv}>
          <div className={styles.templateBtnContainer}>
            <DialogBtn
              action={() => handleDownloadHook(template)}
              text="Template"
            />
          </div>
          <DialogBtn action={() => processUploads(files)} text="Submit" />
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
