import { Link } from 'react-router-dom';
import styles from '../homePage.module.scss';
import { S3_PUBLIC_IMAGES } from '../../../../config';

const background = `${S3_PUBLIC_IMAGES}/website/banner.png`
const girlHeader = `${S3_PUBLIC_IMAGES}/website/girl.png`
const boyHeader = `${S3_PUBLIC_IMAGES}/website/boy2.png`
const superMeLogo = `${S3_PUBLIC_IMAGES}/website/logo_icon.png`

export const HeroSection = (props) => {
  return (
    <section style={{ backgroundImage: `url('${background}')` }} className={styles.heroSection}>
      <div className={styles.heroSectionContainer}>
        <img src={boyHeader} alt="Boy" className={styles.boyHeader} />
        <div className={styles.heroInfo}>
          <img src={superMeLogo} alt="Super Me Logo" className={styles.heroInfoLogo} />
          <h1>Where children create healthy habits through gamification</h1>
          <div className="text-center">
            <Link
              to="/bookdemo"
              className={styles.basic_btn}
            >
              Book Demo
            </Link>
          </div>
        </div>
        <img src={girlHeader} alt="Girl" className={styles.girlHeader} />
      </div>
    </section>
  );
};
