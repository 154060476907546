import { useCallback } from "react";
import { SetOpenDialog } from "../../../Pages/UsersPage/types";
import { FileProcessError } from "../types";
import {
  DialogMainText,
  DialogSubText,
} from "../../dialogComponents/dialogComponents";
import Dialog from "../../dialog/Dialog";

const useDisplayErrors = (setOpenDialog: SetOpenDialog) => {
  const displayErrors = useCallback((errors: FileProcessError[]) => {
    const dialogContent = errors.map((err) => {
      console.log(err);

      const message = err.error.map((singleErr) => {
        let errorDetails;

        if (Array.isArray(singleErr.errorDetails)) {
          errorDetails = singleErr.errorDetails.join(", ");
        }

        const dialogText = errorDetails
          ? `${singleErr.errorTitle} \n ${errorDetails}`
          : singleErr.errorTitle;

        return (
          <>
            <DialogSubText text={dialogText} />{" "}
          </>
        );
      });

      return (
        <div>
          <DialogMainText text={`Errors Processing file: \n ${err.fileName}`} />{" "}
          {message}
        </div>
      );
    });
    setOpenDialog({
      open: true,
      content: (
        <Dialog
          content={
            <>
              <DialogMainText text="Please Try again!" /> {dialogContent}{" "}
              <DialogMainText text="If you continue to experience an error, please contact support for further assistance." />
            </>
          }
        />
      ),
    });
  }, []);
  return displayErrors;
};

export default useDisplayErrors;
