import { S3_PUBLIC_IMAGES } from "../../../config";
import PropTypes from "prop-types"

import useSetViewportHook from "../../../Hooks/useSetViewportHook";
import styles from "./landingPageTemplate.module.scss"

const MainPageTemplate = ({ content, chars, charSize, }) => {
  const images = {
    boyRight_Standing: `${S3_PUBLIC_IMAGES}/website/boyStanding.png`,
    girlLeft_Standing: `${S3_PUBLIC_IMAGES}/userSite/config/girlLeftChabbad.png`,
  };
  useSetViewportHook()

  const mainContent = (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ width: `calc(var(--vw, 1vw) * 100)`, height: `calc(var(--vh, 1vh) * 100)`, overflow: "hidden" }}
      id="section1"
    >
      <div
      >
        <div style={{ height: "100%" }}>
          <div className={`d-flex row align-items-end justify-content-center fp-tableCell ${styles.wrapper}`}>
            {/* Character 1 */}
            {chars &&
              <div
                className="right_boy hey character1_play text-center col-2 "
                style={{ justifyContent: "end" }}
              >
                <img
                  id="thegirl"
                  src={images.boyRight_Standing}
                  alt=""
                  className="img-fluid"
                  style={{
                    height: "auto",
                    width: [charSize],
                  }}
                />
              </div>}

            {/* Main Form */}
            {content}

            {/* Character 2 */}
            {chars &&
              <div className="text-center mx-xxl-1 left_girl hey character2_play col-lg-2 col-xl-2">
                <img
                  id="theImg"
                  src={images.girlLeft_Standing}
                  alt=""
                  className="img-fluid"
                  style={{
                    height: "auto",
                    width: [charSize],
                  }}
                />
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
  return <>{mainContent}</>;
};

MainPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  chars: PropTypes.bool,
  charSize: PropTypes.string
}

MainPageTemplate.defaultProps = {
  chars: true,
}




export default MainPageTemplate;
