import { useCallback } from "react";
import { ExpectedHeaders } from "../Components/bulkUpload/types";

const useCheckHeaders = (expectedHeaders: ExpectedHeaders) => {
  const checkHeaders = useCallback(
    (headers: string[]) => {
      const missingHeaders = [];
      const headersCopy = [...headers];

      for (let i = 0; i < headersCopy.length; i++) {
        headersCopy[i] = headersCopy[i]
          .toLowerCase()
          .replace("(required)", "")
          .replace("(optional)", "")
          .trim();
      }

      const expectedHeadersKeys = Object.keys(expectedHeaders);
      for (const expectedHeader of expectedHeadersKeys) {
        if (!headersCopy.includes(expectedHeader)) {
          const alternatives =
            expectedHeaders[expectedHeader].alternatives || [];
          const found = alternatives.find((alt) => headersCopy.includes(alt));
          if (!found) {
            if (expectedHeaders[expectedHeader].required) {
              missingHeaders.push(expectedHeader);
            }
          } else {
            const i = headersCopy.indexOf(found);
            headersCopy[i] = expectedHeader;
          }
        }
      }
      return { updatedHeaders: headersCopy, missingHeaders };
    },
    [expectedHeaders]
  );
  return checkHeaders;
};

export default useCheckHeaders;
