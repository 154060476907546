import { ReactNode, useEffect, useState } from "react";
import {
  Data,
  CurrentCategories,
  CreatorGameboardConfig,
  StepsReference,
  StepDetails,
  CreateProcess,
} from "../types";

import useUpdateCreatorDialog from "../hooks/useUpdateCreatorDialog";
import { Mission, RenderableContent } from "../../../../types";
import TaskStep from "./TaskStep";
import CategoryStep from "./CategoryStep";
import TaskOptionStep from "./TaskOptionStep";
import TaskCompletedStep from "./TaskCompletedStep";
import AddToGame from "./AddToGame";
import CreateMissionSummary from "./CreateMissionSummary";
import CategoryIconStep from "./CategoryIconStep";
import OptionsIconsStep from "./OptionsIconsStep";
import useCreateMissionDataDefaults from "../hooks/useCreateMissionDataDefaults";
import MethodSelection from "../../../Components/methodSelection/MethodSelection";
import Dialog from "../../../Components/dialog/Dialog";

type Props = {
  missions: Mission[];
  gameboardConfigs: CreatorGameboardConfig[];
  addMissionHandler: (
    mission: Omit<Mission, "_id">,
    addToGame: string,
    createProcess: string
  ) => Promise<RenderableContent>;
  currentCategories: CurrentCategories;
  currentRewardTypes: string[];
};

const CreateMission = ({
  gameboardConfigs,
  addMissionHandler,
  currentCategories,
  currentRewardTypes,
}: Props) => {
  const updateCreatorDialog = useUpdateCreatorDialog();
  const createMissionDataDefaults = useCreateMissionDataDefaults();
  const [currentStepNum, setCurrentStepNum] = useState(0);
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [process, setProcess] = useState<CreateProcess>();
  const [steps, setSteps] = useState<StepDetails | null>(null);
  const [data, setData] = useState<Data>({});

  const stepsReferenceObj: StepsReference = {
    quickCreate: {
      steps: [MethodSelection, OptionsIconsStep, CreateMissionSummary],
      stepDataKeys: [
        "createProcessSelection",
        "optionsIcons",
        "createMissionSummary",
      ],
    },
    advanceCreate: {
      steps: [
        MethodSelection,
        CategoryStep,
        CategoryIconStep,
        TaskStep,
        TaskOptionStep,
        OptionsIconsStep,
        TaskCompletedStep,
        AddToGame,
        CreateMissionSummary,
      ],
      stepDataKeys: [
        "createProcessSelection",
        "category",
        "categoryIcon",
        "task",
        "taskOption",
        "optionsIcons",
        "taskCompleted",
        "addToGame",
        "createMissionSummary",
      ],
    },
  };

  const methodSelectionBtn = {
    btn1: { text: "Quick Create", action: () => setProcess("quickCreate") },
    btn2: {
      text: "Advance Create",
      action: () => setProcess("advanceCreate"),
    },
  };

  useEffect(() => {
    setDialogContent(
      <Dialog
        content={
          <MethodSelection
            mainText="Select A Create Option"
            subText="For a quicker and easier experince, select Quick Create!"
            options={[methodSelectionBtn.btn1, methodSelectionBtn.btn2]}
          />
        }
      />
    );
    createMissionDataDefaults({ process, setData });
    setProcess("quickCreate")
  }, []);

  useEffect(() => {
    if (process) {
      const newSteps = stepsReferenceObj[process];
      setSteps({ steps: newSteps.steps, stepDataKeys: newSteps.stepDataKeys });
      setCurrentStepNum((prev) => prev + 1);
      createMissionDataDefaults({ process, setData });
    }
  }, [process]);

  useEffect(() => {
    if (!steps) return;
    if (currentStepNum === 0) {
      setProcess(undefined);
      setDialogContent(
        <Dialog
          content={
            <MethodSelection
              mainText="Select A Create Option"
              subText="For a quicker and easier experince, select Quick Create!"
              options={[methodSelectionBtn.btn1, methodSelectionBtn.btn2]}
            />
          }
        />
      );

      return;
    }

    const stepDataKeys = steps.stepDataKeys;
    const CurrentStepComponent = steps.steps[currentStepNum];
    const currentStepJsx = (
      <CurrentStepComponent
        data={data}
        setData={setData}
        currentCategories={currentCategories}
        setCurrentStepNum={setCurrentStepNum}
        currentRewardTypes={currentRewardTypes}
        gameboardConfigs={gameboardConfigs}
      />
    );

    updateCreatorDialog(
      stepDataKeys[currentStepNum],
      currentStepJsx,
      currentStepNum,
      stepDataKeys.length,
      setCurrentStepNum,
      setDialogContent,
      data,
      setData,
      addMissionHandler
    );
  }, [data, currentStepNum, steps]);

  return dialogContent || null;
};

export default CreateMission;
