import { useCallback } from "react";
import Cookies from "js-cookie";
import ApiService from "../../../../Services/apiServiceT";
import { UploadSubmitDataKeys } from "../../../Components/bulkUpload/types";
import { UserInfo } from "../types";
import { RenderableContent, SetState } from "../../../../types";
import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";
import { DialogBtn } from "../../../Components/dialogComponents/dialogComponents";
import useObjToExcelHook from "./useObjToExcelHook";
import useHandleDownloadHook from "../../../../Hooks/useHandleDownloadHook";

type Student = {
  id: string;
  fname: string;
  lname: string;
  studentUsername: string;
  last_mission: string;
  date: string;
};

type AddUsersResp = {
  success: Student[];
  failed: Student[];
};

type Props = {
  setUsersInfo: SetState<UserInfo[]>;
  setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>;
};

const useHandleBulkUserUpload = ({ setUsersInfo, setOpenDialog }: Props) => {
  const objToExcelHook = useObjToExcelHook();
  const handleDownloadHook = useHandleDownloadHook();

  const handleBulkUserUpload = useCallback(
    async (processedUploads: Record<UploadSubmitDataKeys, any>) => {
      const apiService = new ApiService();
      const userCookie = Cookies.get("user");
      const userObject = userCookie ? JSON.parse(userCookie) : null;
      const schoolName = userObject?.organization
        ? userObject.organization.replace(/ /g, "").toLowerCase()
        : "school";
      let message: RenderableContent = <></>;
      let customContent = null;

      const processedUploadsKeys = Object.keys(
        processedUploads
      ) as Array<UploadSubmitDataKeys>;
      const postData: any[] = [];
      processedUploadsKeys.forEach((key) => {
        if (Array.isArray(processedUploads[key])) {
          processedUploads[key].forEach((uploadedFile) => {
            postData.push(...uploadedFile.content);
          });
        }
      });
      console.log(...postData);

      try {
        const [addUsersResp, err] = await apiService.post({
          path: "/bulk_add_student",
          dataType: "admin",
          data: { students: postData, schoolName },
        });
        console.log(addUsersResp);
        if (addUsersResp) {
          const data = addUsersResp.data as AddUsersResp;
          const studentSuccessCount =
            data.success.length > 1
              ? `${data.success.length} students were`
              : `${data.success.length} student was`;
          const studentFaildCount =
            data.failed.length > 1
              ? `${data.failed.length} students`
              : `${data.failed.length} student`;

          if (data.success.length > 0) {
            setUsersInfo((prev) => [...prev, ...data.success]);
            if (data.failed.length > 0) {
              const failedReport = objToExcelHook(data.failed);
              message = (
                <>
                  {studentSuccessCount} successfully added. <br /> However,{" "}
                  {studentFaildCount} failed to be created. <br /> The default
                  password for the student accounts is {schoolName}
                  <br /> <br />
                  <DialogBtn
                    text="Download Report"
                    action={() =>
                      handleDownloadHook({
                        fileToDownload: failedReport,
                        fileName: "Failed Students Report.xlsx",
                      })
                    }
                  />
                </>
              );
            } else {
              message = (
                <>
                  Completed <br /> {studentSuccessCount} Successfully <br />{" "}
                  Added <br /> The default password for the student accounts is{" "}
                  {schoolName} <br />
                </>
              );
            }
          } else {
            const failedReport = objToExcelHook(data.failed);
            message = (
              <>
                Upload Failed <br /> {studentFaildCount} <br /> Failed To Upload{" "}
                <br /> <br />{" "}
                <DialogBtn
                  text="Download Report"
                  action={() =>
                    handleDownloadHook({
                      fileToDownload: failedReport,
                      fileName: "Failed Students Report.xlsx",
                    })
                  }
                />
              </>
            );
          }
        } else {
          console.log(err);
          message = (
            <>
              Upload Failed <br /> There was an error uploading the new users.{" "}
              <br /> If this problem persists, please contact support for
              assistance.
            </>
          );
        }
      } catch (error) {
        console.log(error);
        message = (
          <>
            Upload Failed <br /> There was an error uploading the new users.{" "}
            <br /> If this problem persists, please contact support for
            assistance.
          </>
        );
      }

      return (
        <ActionSuccessStatus
          message={message}
          customContent={customContent}
          closeHandler={() => setOpenDialog({ open: false, content: null })}
        />
      );
    },
    [handleDownloadHook, objToExcelHook, setOpenDialog, setUsersInfo]
  );

  return handleBulkUserUpload;
};

export default useHandleBulkUserUpload;
