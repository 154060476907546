import {
  DialogBtn,
  DialogMainText,
  DialogSubText,
} from "../dialogComponents/dialogComponents";
import styles from "./methodSelection.module.scss";

type option = {
  text: string;
  action: () => void;
};

type Props = {
  mainText: string;
  subText: string;
  options: option[];
};

const MethodSelection = ({ mainText, subText, options }: Props) => {
  return (
    <>
      <div className={styles.createProcessSelectionTexts}>
        <DialogMainText text={mainText} />
        <DialogSubText text={subText} />
      </div>

      <div className={styles.createProcessSelectionBtns}>
        {options.map((opt, index) => (
          <DialogBtn
            key={`${opt.text}_btn_${index}`}
            text={opt.text}
            action={opt.action}
          />
        ))}
      </div>
    </>
  );
};

export default MethodSelection;
