import React from "react";
import { InlineWidget } from "react-calendly";

import MainNavBar from "../../Components/mainNavbar/MainNavbar";
import MainFooter from "../../Components/mainFooter/MainFooter";
import ContentBack from "../../Components/contentBack/v1/ContentBack";
import MainPageTemplate from "../../Components/landingPageTemplate/LandingPageTemplate";

import styles from "./bookDemoPage.module.scss";
import { isTablet } from "react-device-detect";

const BookDemoPage = () => {
  const centerContent = (
    <div className={styles.centerContentContainer}>
      <h1 className={styles.title}>Book a Demo</h1>
      <h6 className={styles.subTitle}>
        Schedule most convenient date and time
      </h6>

      <div className="col-12 ajax-field">
        <div
          className={`_calendly_inline_widget form_content ${styles.widgetContainer}`}
        >
          <InlineWidget
            color="#00a2ff"
            text="Request a Demo"
            textColor="#ffffff"
            url="https://calendly.com/superme/30min?hide_event_type_details=1&hide_gdpr_banner=1"
          />
        </div>
      </div>
    </div>
  );

  const content = (
    <div className="col-lg-6 col-xxl-6">
      <ContentBack
        content={centerContent}
        contentWidth={isTablet ? "90vw" : "100%"}
        customClass={styles.contentBackCustomClass}
      />
    </div>
  );
  const mainContent = <MainPageTemplate content={content}/>;

  return (
    <>
      <MainNavBar />
      {mainContent}
      <MainFooter />
    </>
  );
};

export default BookDemoPage;
