import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import ApiService from "../../../Services/apiService";
import useUserQuests from '../../Hooks/useUserQuests';
import UserNavBar from "../../Components/userNavbar/UserNavBar";
import { QuestBoard } from './components/QuestBoard';
import { QuestSideBoard } from './components/QuestSideBoard';
import Loading from '../../../Components/loading/Loading';
import DialogTemplate from "../../../Components/DialogTemplate";

import { QuestTileStatuses } from '../../../Models/questModel';
import * as functionalCalc from '../../../Util/functionalCalc';
import * as utils from "../../../Util/utils";

import styles from './questPage.module.scss';
import useSetViewportHook from '../../../Hooks/useSetViewportHook';


const checkPrerequisites = (prerequisites, tiles) => {
    return prerequisites.every(id => {
        const prerequisiteTile = tiles.find(tile => tile.id === id);
        return prerequisiteTile && (prerequisiteTile.status === QuestTileStatuses.Completed || prerequisiteTile.status === QuestTileStatuses.Collected);
    });
};

const QuestPage = (props) => {
    useSetViewportHook()
    const apiService = new ApiService(true);
    const queryClient = useQueryClient();
    const { data: userQuestsData, isLoading: isLoadingQuests, refetch } = useUserQuests();

    const [selected, setSelected] = useState();
    let [questTiles, setQuestTiles] = useState([]);
    const [dialog, setDialog] = useState(null);

    let statsConfig = {};
    let reward = [];
    let userData = {}
    let questboardPos = -1

    let questCompleted = 0;
    let nextReward = {}


    const onTileClicked = (tile) => {
        setSelected(selected !== tile ? tile : null);
    };

    const onTileCollected = async (tile) => {

        let updatedQuests = [];
        const newQuestTiles = questTiles.map((item, index) => {
            if (item.id === tile.id) updatedQuests.push({ id: item.id, status: QuestTileStatuses.Collected, date: tile.date });
            else if (userQuestsData.questTiles.at(index).status !== item.status) updatedQuests.push({ id: item.id, status: item.status, date: item.date });

            return item.id === tile.id ? { ...item, status: QuestTileStatuses.Collected } : item
        });


        const [qcResp, qcError] = await apiService.patch({
            path: "/quests",
            dataType: "user",
            data: {
                quests: updatedQuests,
                questboardPos: questboardPos,
                rewards: tile.reward,
            }
        });
        // Update the cached data if the stale time is NOT set to  '0'

        if (qcResp.data) {
            //Show modal that they completed the Questboard
            setDialog({
                mainText: "Wow! Great job completing all the Super Quests!",
                subText: `Dont Stop now! Here are new quests!`,
                duration: "1000ms",
                closeHandler: () => setDialog(null),
            });

            // Update the cache with the new data
            await queryClient.setQueryData(['userQuests'], oldData => {
                return {
                    questTiles: qcResp.data.questTiles,
                    statsConfig: qcResp.data.statsConfig,
                    reward: qcResp.data.reward,
                    questboardPos: qcResp.data.questboardPos,
                    userData: oldData.userData
                }
            });
            // refetch();
        }
        else {
            setQuestTiles(newQuestTiles);
        }


    }

    useEffect(() => {
        if (userQuestsData) {
            // console.log(JSON.stringify(userQuestsData, null, 2));
            setQuestTiles(JSON.parse(JSON.stringify(userQuestsData.questTiles)));
        }
    }, [userQuestsData]);


    // Loading data check
    if (!isLoadingQuests) {
        // questTiles = userQuestsData.questTiles;
        statsConfig = userQuestsData.statsConfig;
        reward = userQuestsData.reward;
        userData = userQuestsData.userData;
        questboardPos = userQuestsData.questboardPos;

        // Initilize the nextReward
        Object.keys(statsConfig).map((key, index) => {
            const category = (functionalCalc.splitEquationByOperators(statsConfig[key].calc))[0].split('_')[0].replace('.', '');

            nextReward[category] = {
                count: Infinity,
                description: key,
            }
        });

        // Calculate Stats
        if (questTiles.length > 0) {

            // console.log(questTiles[9]);
            // console.log(functionalCalc.evaluateEquations(questTiles[8].quest, userData, new Date(questTiles[8].date)));
            // userData.comMissions.map(missions => {
            //     console.log(missions);
            //     console.log('Set');
            //     console.log(new Date(missions.date));
            //     console.log(new Date(missions.date) >= new Date(questTiles[8].date))
            //     console.log('Set');
            // });


            for (let tile of questTiles) {

                if (tile.status === QuestTileStatuses.Collected || tile.status === QuestTileStatuses.Completed) {
                    questCompleted += 1;
                    continue;
                }

                // Check if a tile is locked
                if (!checkPrerequisites(tile.prerequisite, questTiles)) {
                    tile.status = QuestTileStatuses.Locked;
                }
                else {
                    if (tile.date === "" || tile.date === undefined || tile.status === QuestTileStatuses.Locked) tile.date = new Date();

                    // Check if a quest has been completed.
                    if (functionalCalc.evaluateEquations(tile.quest, userData, tile.date)) {
                        tile.status = QuestTileStatuses.Completed;
                        questCompleted += 1;
                    }
                    // Check the tile progress
                    else {

                        tile.status = QuestTileStatuses.Inprogress;

                        // Calculate nextReward
                        tile.quest.forEach(quest => {
                            const calcSplit = functionalCalc.splitEquationByOperators(quest);
                            const category = calcSplit[0].split('_')[0].replace('.', '');

                            if (nextReward[category].count > calcSplit[calcSplit.length - 1]) {
                                nextReward[category].count = calcSplit[calcSplit.length - 1];
                            }

                        });
                    }
                }
            }
        }
    }

    return (
        <>
            <UserNavBar />
            <Loading isLoading={isLoadingQuests} />
            {!isLoadingQuests &&
                <div className={styles.questPage}
                    onClick={(e) => {
                        if (e.target.getAttribute('data-role') !== 'tile') {
                            setSelected(null);
                        }
                    }}
                >
                    <div className={styles.boardWrapper}>
                        <QuestBoard onTileClicked={onTileClicked} activeTile={selected} userData={userData} tiles={questTiles} onTileCollected={onTileCollected} />
                        <QuestSideBoard data={{ ...userData, questCompleted: questCompleted, nextReward: nextReward }} statsConfig={statsConfig} />
                    </div>
                </div>
            }
            {dialog && <DialogTemplate {...dialog} />}
        </>
    );
};

export default QuestPage;