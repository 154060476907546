import { useCallback } from "react";
import * as XLSX from "xlsx";

const useObjToExcelHook = () => {
  const objToExcelHook = useCallback((data: Record<string, any>[]) => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Calculate the column widths dynamically
    const colWidths = Object.keys(data[0] || {}).map((key) => {
      const maxWidth = Math.max(
        key.length, // Header length
        ...data.map((row) => (row[key] ? row[key].toString().length : 0)) // Max data length
      );
      return { wch: maxWidth + 2 }; // Add some padding for better spacing
    });

    worksheet["!cols"] = colWidths; // Set the column widths

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: "application/octet-stream" });

    return excelBlob;
  }, []);

  return objToExcelHook;
};

export default useObjToExcelHook;
