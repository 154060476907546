import { React, useState } from "react";
import { useNavigate } from "react-router";
import { useQueryClient } from '@tanstack/react-query'
import Cookies from "js-cookie";

import UserPageTemplate from "../../Components/userPageTemplate/UserPageTemplate";
import Profile from "./componets/Profile";
import ChangePassForm from "./componets/ChangePassForm";
import ChangePassLogic from "../../../Components/ChangePassLogic";
import useBreakpoint from "../../../Hooks/use-breakpoint";

import ApiService from "../../../Services/apiService";

import cssModule from "./userProfilePage.module.scss"
import main_css from "../../../assets/main_css/main.module.css";

const UserProfilePage = () => {
  const apiService = new ApiService();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { breakpoint } = useBreakpoint()
  const [formType, setFormType] = useState('Profile');

  const formsList = {
    Profile: {
      lable: "Profile",
      form: <Profile />,
    },
    ChangePassForm: {
      lable: "ChangePassForm",
      form: <ChangePassLogic form={<ChangePassForm />} />,
    },
  };

  const formChangerHandler = (tab) => {
    setFormType(tab);
  };

  const logoutHandler = async () => {
    try {
      const [response, error] = await apiService.get({
        path: "/",
        dataType: "logout",
      });

      for (var cookie in Cookies.get()) {
        Cookies.remove(cookie);
      }

      queryClient.clear();

      navigate("/");
      if (breakpoint === 'mobile') {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const centerContent = (
    <>
      <div className="d-flex flex-row" style={{ justifyContent: 'space-evenly' }}>
        <div className="text-center">
          <button
            className={main_css.basic_btnInv}
            style={{ padding: '5px 10px' }}
            onClick={() => {
              formChangerHandler('Profile');
            }
            }
          >
            Profile
          </button>
        </div>
        <div className="text-center">
          <button
            className={main_css.basic_btnInv}
            style={{ padding: '5px 10px' }}
            onClick={() => {
              formChangerHandler('ChangePassForm');
            }
            }
          >
            Change Password
          </button>
        </div>
        <div className="text-center">
          <button
            className={main_css.basic_btnInv}
            style={{ padding: '5px 10px' }}
            onClick={logoutHandler}
          >
            Logout
          </button>
        </div>
      </div>
      <div className={` border1`} style={{ paddingRight: '10px', marginTop: '2%', paddingTop: '3%', overflow: 'auto' }}>
        {formsList[formType]["form"]}
      </div>
    </>
  );

  const mainContent = (
    <div
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "end",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className={cssModule.page} >
        {centerContent}
      </div>
    </div>
  );

  return (
    <>
      <UserPageTemplate pageContent={mainContent} footer footerClass={"scrollableFooter"} />
    </>
  );
};

export default UserProfilePage;
