import { SetState } from "../../../../types";
import { validation } from "../../../../Util/Validation";
import ActionSuccessStatus from "../../../Components/actionSuccessStatus/ActionSuccessStatus";
import BulkUpload from "../../../Components/bulkUpload/BulkUpload";
import { UploadSubmitDataKeys } from "../../../Components/bulkUpload/types";
import useHandleBulkUserUpload from "../hooks/useHandleBulkUserUpload";
import { SetOpenDialog, UserInfo } from "../types";
import { S3_PUBLIC } from "../../../../config";

type Props = {
  setOpenDialog: SetOpenDialog;
  setUsersInfo: SetState<UserInfo[]>;
};

const StudentBulkUpload = ({ setOpenDialog, setUsersInfo }: Props) => {
  const handleBulkUserUpload = useHandleBulkUserUpload({
    setUsersInfo,
    setOpenDialog,
  });

  const submitHandler = async (
    processedUploads: Record<UploadSubmitDataKeys, any>
  ) => {
    if (Object.keys(processedUploads).length < 1) return;
    setOpenDialog({
      open: true,
      content: (
        <ActionSuccessStatus
          message={
            <>
              Loading..... <br /> Please Wait
            </>
          }
        />
      ),
    });
    const content = await handleBulkUserUpload(processedUploads);
    setOpenDialog({ open: true, content: content });
  };

  const expectedHeaders = {
    "first name": {
      required: true,
      alternatives: ["firstname", "fname"],
      pattern: "^[A-Za-z]+( [A-Za-z]+)*$",
    },
    "last name": {
      required: true,
      alternatives: ["lastname", "lname"],
      pattern: "^[A-Za-z]+( [A-Za-z]+)*$",
    },
    "student's email": {
      required: false,
      alternatives: [
        "students email",
        "studentsemail",
        "student email",
        "studentemail",
      ],
      pattern: validation.email.pattern,
    },
    grade: { required: true, alternatives: [], pattern: "" },
    "parent's email": {
      required: true,
      alternatives: [
        "parents email",
        "parentsemail",
        "parent email",
        "parentemail",
      ],
      pattern: validation.email.pattern,
    },
  };

  const templateUrl =
    `${S3_PUBLIC}/templates/studentBulkUpload.xlsx`

  return (
    <>
      {
        <BulkUpload
          mainText="Student Bulk Upload"
          dragText="Drop, drag or click to upload an Excel file!"
          template={{
            fileToDownload: templateUrl,
            fileName: "Student Bulk Upload Template",
          }}
          submitHandler={submitHandler}
          acceptedFileTypes={["excel"]}
          expectedHeaders={expectedHeaders}
          setOpenDialog={setOpenDialog}
        />
      }
    </>
  );
};

export default StudentBulkUpload;
