import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { S3_PUBLIC_IMAGES } from '../../../config';

import ApiService from "../../../Services/apiService";
import MainNavBar from "../../Components/mainNavbar/MainNavbar";
import MainFooter from "../../Components/mainFooter/MainFooter";
import ContentBack from '../../Components/contentBack/v2/ContentBackV2';
import FormInput from "../../../Components/FormInput";
import DialogTemplate from "../../../Components/DialogTemplate";

import { validation } from "../../../Util/Validation";

import main_css from "../../../assets/main_css/main.module.css"

import styles from "./contactUs.module.scss"

const images = {
    phone: `${S3_PUBLIC_IMAGES}/website/phone.png`,
};

const ContactUs = () => {
    const apiService = new ApiService(true);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [dialog, setDialog] = useState(null);

    const sendEmail = async () => {
        setDialog({
            mainText: `Sending Email...`,
            subText: "Please wait while we send your message to support.",
            duration: "0ms",
        });

        // Call your API endpoint here
        const [emailResp, emailError] = await apiService.post({
            path: "/contactUs",
            dataType: "support",
            data: {
                email: email,
                message: message
            }
        });

        // Based on the response, you can add your if-else statement here
        if (!emailError) {
            // Do something
            setDialog({
                mainText: `Email Sent!`,
                subText: "Check your email for a response from support shortly",
                duration: "0ms",
                closeHandler: () => {
                    setDialog(null);
                    navigate("/")
                }
            });
        } else {
            // Do something else
            setDialog({
                mainText: "Oops!",
                subText:
                    "Something went wrong!\nPlease check the information you entered and try again.",
                subTextClass: "px-lg-3",
                closeHandler: () => setDialog(false),
            });
        }
    };

    const centerContent = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: 'white' }}>
            <h1>Contact Us</h1>

            <div style={{ padding: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', paddingBlock: '10px' }}>
                    <h4 style={{ paddingRight: '15px' }}>Call:</h4>
                    <div>								<a href="tel:+1(424)231-4195">
									<div style={{
										display: 'flex',
										alignItems: 'center'
									}}>
										<img src={images["phone"]} className="img-fluid" alt="" />+1 (321) 325-0583
									</div>
								</a></div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'start', paddingBlock: '10px' }}>
                    <h4 style={{ paddingRight: '15px' }}>Email Us:</h4>
                    <div className={styles.emailFormInputContainer}>
                        <FormInput
                            type={"email"}
                            value={email}
                            placeholder={"Email"}
                            name={"email"}
                            onChangeHandler={setEmail}
                            message={validation.email.message}
                            pattern={validation.email.pattern}
                        />
                    </div>
                    <textarea
                        placeholder="Your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{ height: '25vh', color: "black" }}
                    />
                    <div className="text-center">
                        <button
                            onClick={sendEmail}
                            className={main_css.basic_btnInv}
                            style={{ marginBlock: '3%', padding: '5px 10px' }}
                        >
                            Send Email
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );

    const mainContent = (
        <div
            style={{
                backgroundColor: 'rgba(68, 94, 170, 0.50)',
                borderRadius: '10%',
                padding: '5%',
                width: '100%'
                // margin: "auto",
            }}
        >

            <div style={{
                backgroundColor: 'rgba(68, 94, 170, 0.75)',
                borderRadius: '5%',
                padding: '5%',
            }}>

                {centerContent}

            </div>

        </div>
    );

    return (
        <>
            {/* <MainNavBar /> */}
            {/* {mainContent} */}
            {dialog && <DialogTemplate {...dialog} />}
            <ContentBack pageContent={mainContent} Navbar={MainNavBar} centerDivCol='col-sm-12'/>
            <MainFooter />
        </>

    );
};

export default ContactUs;
