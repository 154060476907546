import cssModule from "./carouselCard.module.css";
import { S3_PUBLIC_IMAGES } from "../../../../../config";

const images = {
  stars_banner: `${S3_PUBLIC_IMAGES}/userSite/achievements_banner.png`,
  gold_trophy: `${S3_PUBLIC_IMAGES}/campaign/mitzvah/gold_trophy.png`,
  silver_trophy: `${S3_PUBLIC_IMAGES}/campaign/mitzvah/silver_trophy.png`,
  bronze_trophy: `${S3_PUBLIC_IMAGES}/campaign/mitzvah/bronze_trophy.png`
};

const CarouselCard = ({ title, rankingsList }) => {
  const subRankingsList = rankingsList.slice(3);
  const subRanks = subRankingsList.map((rankerName, index) => {
    return (
      <div key={`${rankerName}_${index}`} className={cssModule.ranking}>
        <div className={cssModule.indexNum}>{index + 4}</div>
        <div className={`${cssModule.text} ${cssModule.rankerName}`}>
          {rankerName}
        </div>
      </div>
    );
  });

  return (
    <div className={cssModule.carouselBlock}>
      <div className={cssModule.ach_carasolItem}>
        <div className={cssModule.ach_carasolItem_bkg}>
          <div className={cssModule.carouselInner}>
            <div className={`box_text text-center ${cssModule.box_text}`}>
              <div
                className={`heading_foam text-center text-uppercase lable ${cssModule.title}`}
              >
                {title}
              </div>
              <div className={cssModule.leaderBoard}>
                <div className={cssModule.ranking}>
                  <div>
                    <img
                      className={cssModule.trophy}
                      src={images.gold_trophy}
                      alt=""
                    />
                  </div>
                  <div className={`${cssModule.text} ${cssModule.rankerName}`}>
                    {rankingsList[0]}
                  </div>
                </div>
                <div className={cssModule.ranking}>
                  <div>
                    <img
                      className={cssModule.trophy}
                      src={images.silver_trophy}
                      alt=""
                    />
                  </div>
                  <div className={`${cssModule.text} ${cssModule.rankerName}`}>
                    {rankingsList[1]}
                  </div>
                </div>
                <div className={cssModule.ranking}>
                  <div>
                    <img
                      className={cssModule.trophy}
                      src={images.bronze_trophy}
                      alt=""
                    />
                  </div>
                  <div className={`${cssModule.text} ${cssModule.rankerName}`}>
                    {rankingsList[2]}
                  </div>
                </div>
                {subRanks}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
