import styles from "./dialogComponents.module.scss";

type DialogTextProps = {
  text: string;
};

type DialogBtnProps = {
  text: string;
  action: () => void;
};

type DialogBackBtnProp = {
  action: () => void;
};

export const DialogMainText = ({ text }: DialogTextProps) => {
  return <div className={`${styles.mainText} text-center`}>{text}</div>;
};

export const DialogSubText = ({ text }: DialogTextProps) => {
  return <div className={`${styles.subText} text-center`}>{text}</div>;
};

export const DialogBtn = ({ text, action }: DialogBtnProps) => {
  return (
    <button
      className={`${styles.btn} btn ${styles.continueBtn}`}
      onClick={action}
    >
      {text}
    </button>
  );
};

export const DialogBackBtn = ({ action }: DialogBackBtnProp) => {
  return (
    <button className={`${styles.btn} btn`} onClick={action}>
      Back
    </button>
  );
};
