import React, { PropsWithChildren } from 'react';
import { useDrag } from 'react-dnd';
import { ToolConfig, ToolConfigWithId } from '../game/game-config';
import clsx from 'clsx';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Directions } from '@mui/icons-material';

export const Inventory: React.FunctionComponent<InventoryProps> = (props) => {
  const iconPos = props.direction === "left" ? "right-0" : "left-0"
  const hidden = props.hidden ? "d-none" : "";
  return (
    <div className={`absolute top-[100px] ${iconPos} p-4 flex flex-col gap-4 z-30 ${hidden}`} onClick={props.setOpenInventory}>
      <div
        className={'h-12 p-2 bg-brand/50 text-white rounded-md flex flex-col gap-2 justify-center items-center hover:scale-110 transition-all cursor-pointer select-none'}
      >
        <div className="flex gap-2 items-center">
          <ConstructionIcon></ConstructionIcon>
        </div>
      </div>
    </div>
  );
};

interface InventoryProps {
  direction: string,
  setOpenInventory: () => void,
  hidden: boolean,
  // tools: Record<string, ToolConfig>;
}
