import { useCallback } from "react";
import { ErrorsObjValues } from "../types";

const useCheckError = () => {
  const checkError = useCallback(
    (
      value: unknown
    ): value is { errorTitle: string; errorDetails: ErrorsObjValues }[] => {
      if (!Array.isArray(value)) return false;

      return value.every(
        (item) =>
          typeof item === "object" &&
          item !== null &&
          "errorTitle" in item &&
          typeof item.errorTitle === "string" &&
          "errorDetails" in item &&
          (typeof item.errorDetails === "string" ||
            Array.isArray(item.errorDetails) ||
            typeof item.errorDetails === "boolean" ||
            typeof item.errorDetails === "number")
      );
    },
    []
  );
  return checkError;
};

export default useCheckError;
