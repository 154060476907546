import Dialog from "@mui/material/Dialog";

import { S3_PUBLIC_IMAGES } from "../../../../config";

import missionsCSS from "../missionsPage.module.scss";

const images = {
  close: `${S3_PUBLIC_IMAGES}/close.png`,
  success: `${S3_PUBLIC_IMAGES}/userSite/achievements_banner.png`,
};

const CompletedDialog = ({ open, handleClose, mission }) => {

  return (
    <Dialog open={open} onClose={handleClose}
      PaperProps={{
        className: missionsCSS.mission_modal_dialog,
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          justifyContent: 'center',
        },
      }}
    >
      <div className={` ${missionsCSS.modal_content}`}>

        <div className={missionsCSS.close_btn} onClick={() => handleClose("completed")} alt="Close">&#10005;</div>

        <div className={missionsCSS.modal_details}>
          <div className={missionsCSS.modalCat}>
            {mission['category']}
          </div>
          <div className={missionsCSS.modalTask}>
            {mission['task']}
          </div>
        </div>
        <div className={missionsCSS.model_result}>
          <img src={images["success"]} alt='' className='' style={{}} />
          <div>Task Completed!</div>
        </div>
      </div>
    </Dialog>
  );
};

export default CompletedDialog;
