import {React} from 'react'
import {Dialog} from '@mui/material'
import { S3_PUBLIC_IMAGES } from '../config';

import useUserConfig from '../UserPages/Hooks/useUserConfig';
import FormInput from "./FormInput";

const assets = require.context("../assets/images", true);

const images = {
  close: `${S3_PUBLIC_IMAGES}/close.png`,
  dialogBkg: './CenterBox_lg.png'
}

const styles = {
  closeIcon: {
    width: 'clamp(25px, 7vw, 40px)',
    height: 'clamp(25px, 7vw, 40px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#445eaa',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
  },
  text: {
    color: '#fdeaff',
    whiteSpace: 'pre-wrap',
    fontFamily: 'Montserrat',
  },
  mainText: {
    fontSize: 'clamp(24px, 3vw, 40px)',
  },
  subText: {
    fontSize: 'clamp(16px, 2.5vw, 30px)',
  },
};

const DialogTemplate = ({ classes = "", mainText = "", mainTextClass = "", subText = "", subTextClass = "", input = null, closeHandler = undefined, button1Text = null, onClickButton1 = null, button2Text = null, onClickButton2 = null, buttonClass = "", duration = '1000ms' }) => {

  const { data: userConfig, isLoading } = useUserConfig();

  const dialogBkg = isLoading ? images.dialogBkg : userConfig.graphicConfig.dialogBkg;

  return (
    <>
      <Dialog
        className={`dialogMui ${classes}`}
        open={true}
        PaperProps={{
          style: {
            animationDuration: duration,
            backgroundImage: isLoading ? 'none' : `url(${assets(`${dialogBkg}`)})`
          },
        }}>
        {/* <div class="d-flex justify-content-center align-item-center" style={{height: "100%", width: "100%", position: "absolute", top: "50%"}}>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div> */}
        {
          closeHandler &&
          <div className="ms-5 ps-5 text-center" style={{ cursor: "pointer", position: "absolute", top: "-2%", right: '7%', }}>
            <div id="closeModal" style={styles.closeIcon} onClick={closeHandler} src={images["close"]} alt="Close">&#10005;</div>
          </div>
        }
        <div
          // style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          style={{ height: "100%" }} className="d-flex flex-column align-items-center justify-content-center ">
          <div className="mx-1 px-1">
            <p className={`text-center ${mainTextClass}`} style={{ ...styles.mainText, ...styles.text }}>{mainText}</p>
          </div>
          <div className="pt-2 mx-1 px-2">
            <p className={`text-center ${subTextClass}`} style={{ ...styles.subText, ...styles.text }}>{subText}</p>
          </div>
          {input && (
            <FormInput
              {...input}
            />
          )}
          <div className={`d-flex justify-content-center ${buttonClass}`}>
            {button1Text !== null && <div className='p-2'>
              <button type='button' onClick={onClickButton1} style={{ backgroundColor: "#445eaa" }} className='btn p-2 text-white'>{button1Text}</button>
            </div>}
            {button2Text !== null && <div className='p-2'>
              <button type='button' onClick={onClickButton2} style={{ backgroundColor: "#445eaa" }} className='btn p-2 text-white'>{button2Text}</button>
            </div>}
          </div>
        </div>

      </Dialog>
    </>
  )
}

export default DialogTemplate;
