import {ObstacleConfig, ToolConfigWithId} from './game-config';
import {Sprite} from './sprite';
import {Block, expandRect, getPathFromSVG, pointInRect, rectanglesIntersect} from './utils';
import {GameContext} from './game-context';
import {Character} from './character';
import {StarsAnimation} from '../animations/stars.animation';
import {PulseAnimation} from "../animations/pulse.animation";

export class Obstacle<Config extends ObstacleConfig = ObstacleConfig> extends Sprite<Config> {
  public blocks: Array<Block> = [];
  public isCompleted = this.config.isCompleted;
  public isHovered = false;
  public currentTool: ToolConfigWithId | null = null;
  public threshold: number = 50;

  private isDroppingTool = false;
  private isBlockingCharacter = false;

  constructor(
    public context: GameContext,
    protected config: Config
  ) {
    super(context, config);
    this.context.addObstacle(this);
    if (this.config.pathImageUri) {
      getPathFromSVG(this.config.pathImageUri).then((lines) => {
        this.blocks = lines.map((line, index) => {
          return {
            start: this.p.createVector(line.start.x, line.start.y),
            end: this.p.createVector(line.end.x, line.end.y),
            disabled: false,
            id: `${this.getId()}-block-${index}`,
          };
        });
      });
    }
    if(!this.isCompleted) {
      this.animations.set('stars', new StarsAnimation({ count: 50 }, this, false));
      this.animations.set('pulse', new PulseAnimation({ speed: 10 }, this, false))
    }
  }

  setup() {
    super.setup();

    const mouseListener = (e: any) => {
      let position = this.p
        .createVector(e.offsetX , e.offsetY);

      if(e.type === 'touchmove') {
        e.preventDefault();
        position = this.p.createVector(e.touches[0].clientX, e.touches[0].clientY);
      }

      const viewPort = this.context.calculateViewport();
      const mousePosition = position
        .sub(this.context.worldOffset.copy().add(viewPort.width / 2, viewPort.height  / 2).mult(this.context.worldScale))
        .div(this.context.worldScale);
      this.isHovered = pointInRect(mousePosition, {
        x: this.position.x - this.threshold,
        y: this.position.y - this.threshold,
        width: this.width + this.threshold * 2,
        height: this.height + this.threshold * 2,
      });
    };

    this.context.addMouseMoveListener(mouseListener);
    this.context.addMouseDragListener(mouseListener);
  }

  getId(): string {
    return this.config.id;
  }

  draw() {
    this.p.push();
    if (this.isCompleted || this.isHovered || this.isBlockingCharacter) {
      if (!this.isCompleted) {
        if(!this.isDroppingTool) {
          this.opacity = 150;
        }
        if (this.isHovered) {
          this.opacityTint = this.p.color(255, 255, 255);
        }
        if (this.isBlockingCharacter) {
          this.opacityTint = this.p.color(255, 0, 0);
        }
      }else {
        this.opacity = 255;
        this.opacityTint = this.p.color(255, 255, 255);
      }
      super.draw();
    }
    this.p.pop();
  }

  public getBlocks() {
    return this.blocks.map((block) => ({
      ...block,
      start: this.p.createVector(block.start.x, block.start.y).add(this.position),
      end: this.p.createVector(block.end.x, block.end.y).add(this.position),
    }));
  }

  public checkDropTool(tool: ToolConfigWithId): boolean {
    this.isDroppingTool = this.isHovered && !this.isCompleted && Boolean(this.config.requiredTools?.includes(tool.id));

    if (!this.isDroppingTool) {
      this.opacity = 30
      this.stopAnimation('pulse');
    } else {
      this.startAnimation('pulse');
    }
    return this.isDroppingTool;
  }

  public isCharacterNear(character: Character, threshold = this.threshold) {
    return rectanglesIntersect(expandRect(character.boundingBox, threshold * 2), this.boundingBox);
  }

  public checkBlockingCharacter(character: Character) {
    if (this.isCompleted) return false;

    this.isBlockingCharacter = this.isCharacterNear(character);
    return rectanglesIntersect(character.nextBoundingBox(), this.boundingBox);
  }

  public isCharacterWithin(character: Character, threshold = 5) {
    return pointInRect(character.center, expandRect(this.boundingBox, threshold));
  }

  public isDangerous() {
    return this.config.isDangerous;
  }
}
