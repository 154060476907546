export const fileTypesConstructor = (
  types: ("csv" | "excel" | "pdf" | "img" | "gif")[]
): string[] => {
  const fileTypes: string[] = [];

  if (types.includes("csv")) {
    fileTypes.push("text/csv");
  }
  if (types.includes("excel")) {
    fileTypes.push(
      "application/vnd.ms-excel",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.macroEnabled.12",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/wps-office.xls"
    );
  }
  if (types.includes("pdf")) {
    fileTypes.push("application/pdf");
  }
  if (types.includes("img")) {
    fileTypes.push("image/jpeg", "image/png");
  }
  if (types.includes("gif")) {
    fileTypes.push("image/gif");
  }

  return fileTypes;
};

export const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes === 0) return "0 Bytes";

  const units = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));

  const size = sizeInBytes / Math.pow(1024, i);
  return `${size.toFixed(2)} ${units[i]}`;
};
