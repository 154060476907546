import { useCallback } from "react";
import * as XLSX from "xlsx";
import { JsonData } from "../../types";
import {
  Errors,
  ExpectedHeaders,
  FileWithId,
} from "../Components/bulkUpload/types";
import useCheckHeaders from "./useCheckHeaders";
import { capitalizeWords } from "../../Util/utils";

const useProcessExcelHook = (expectedHeaders: ExpectedHeaders) => {
  const checkHeaders = useCheckHeaders(expectedHeaders);

  const processExcelHook = useCallback(
    (file: FileWithId) => {
      return new Promise((resolve, reject) => {
        const errors: Errors = [];
        const reader = new FileReader();

        reader.onload = (e) => {
          const result = e.target?.result;
          if (result && typeof result !== "string") {
            const data = new Uint8Array(result);
            const workbook = XLSX.read(data, { type: "array" });

            const sheetName = workbook.SheetNames[0];
            if (!sheetName) {
              reject("No sheets found in the Excel file.");
              return;
            }

            const worksheet = workbook.Sheets[sheetName];
            if (!worksheet) {
              reject("Failed to find data in the selected sheet.");
              return;
            }

            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
            }) as Array<string | number | null>[];

            let lastNonEmptyRowIndex = jsonData.length - 1;
            while (
              lastNonEmptyRowIndex >= 0 &&
              jsonData[lastNonEmptyRowIndex].every(
                (cell) => cell === null || cell === undefined || cell === ""
              )
            ) {
              lastNonEmptyRowIndex--;
            }

            jsonData.splice(lastNonEmptyRowIndex + 1);

            const emptyRowIndices: number[] = [];

            jsonData.forEach((row, index) => {
              if (
                index < jsonData.length - 1 &&
                row.every(
                  (cell) => cell === null || cell === undefined || cell === ""
                )
              ) {
                emptyRowIndices.push(index + 1);
              }
            });

            if (emptyRowIndices.length > 0) {
              errors.push({
                errorTitle: "Empty Rows Found At: ",
                errorDetails: [
                  `${emptyRowIndices.join(", ")}`,
                ],
              });
            }

            let [headerRow, ...dataRows] = jsonData as JsonData;
            const checkedHeadersResult = checkHeaders(headerRow);
            const missingHeaders = checkedHeadersResult.missingHeaders;
            headerRow = checkedHeadersResult.updatedHeaders;
            if (missingHeaders.length > 0) {
              errors.push({
                errorTitle: "Missing Headers",
                errorDetails: missingHeaders,
              });
              reject(errors);
            }

            const objectData = dataRows.map((row, rowIndex) => {
              return headerRow.reduce((obj, key, index) => {
                if (
                  !row[index] &&
                  expectedHeaders[key] &&
                  expectedHeaders[key].required &&
                  !emptyRowIndices.includes((rowIndex + 2))
                ) {
                  errors.push({
                    errorTitle: "Missing Required Field: ",
                    errorDetails: [
                      `Row ${
                        rowIndex + 2
                      } is missing field "${capitalizeWords(key)}"`,
                    ],
                  });
                }
                if (typeof row[index] === "string" && row[index]) {
                  row[index] = row[index].trim();
                }
                if (row[index] && expectedHeaders[key]?.pattern) {
                  const regex = new RegExp(expectedHeaders[key].pattern);
                  const rowIndexString = row[index].toString();
                  if (!regex.test(rowIndexString)) {
                    errors.push({
                      errorTitle: "Validation Error: ",
                      errorDetails: [
                        `Please enter a valid input for "${capitalizeWords(
                          key
                        )}" on row ${rowIndex + 2}`,
                      ],
                    });
                  }
                }
                obj[key] = row[index];
                return obj;
              }, {} as Record<string, string | number>);
            });

            if (errors.length > 0) {
              reject(errors);
            } else {
              resolve(objectData);
            }
          } else {
            reject("Failed to process the Excel file.");
          }
        };

        reader.onerror = () => reject("Error reading file.");
        reader.readAsArrayBuffer(file);
      });
    },
    [checkHeaders, expectedHeaders]
  );
  return processExcelHook;
};

export default useProcessExcelHook;
