import { useCallback } from "react";

type DownloadObj = {
  fileToDownload: string | Blob; // Accept either a URL or Blob
  fileName?: string; // Make fileName optional with a fallback
};

const useHandleDownloadHook = () => {
  const handleDownloadHook = useCallback((downloadObj: DownloadObj) => {
    const { fileToDownload, fileName } = downloadObj;

    let href: string;
    let tempUrlCreated = false;

    if (fileToDownload instanceof Blob) {
      href = URL.createObjectURL(fileToDownload);
      tempUrlCreated = true;
    } else if (typeof fileToDownload === "string") {
      href = fileToDownload;
    } else {
      console.error("Invalid fileToDownload type");
      return;
    }

    const link = document.createElement("a");
    link.href = href;
    link.download = fileName || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (tempUrlCreated) {
      URL.revokeObjectURL(href);
    }
  }, []);

  return handleDownloadHook;
};

export default useHandleDownloadHook;
