import { useEffect } from "react";

// CSS USAGE
//  height: calc(var(--vh, 1vh) * 100)

const useSetViewportHook = () => {
  useEffect(() => {
    const setViewports = () => {
      const vh = window.innerHeight * 0.01;
      const vw = window.innerWidth * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      document.documentElement.style.setProperty("--vw", `${vw}px`);
    };

    setViewports();

    window.addEventListener("resize", setViewports);

    return () => {
      window.removeEventListener("resize", setViewports);
    };
  }, []);
};

export default useSetViewportHook;
