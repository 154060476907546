import Attack000 from './Attack_000.png';
import Attack001 from './Attack_001.png';
import Attack002 from './Attack_002.png';
import Attack003 from './Attack_003.png';
import Attack004 from './Attack_004.png';
import Attack005 from './Attack_005.png';
import Attack006 from './Attack_006.png';
import Attack007 from './Attack_007.png';
import Die000 from './Die_000.png';
import Die001 from './Die_001.png';
import Die002 from './Die_002.png';
import Die003 from './Die_003.png';
import Die004 from './Die_004.png';
import Die005 from './Die_005.png';
import Die006 from './Die_006.png';
import Die007 from './Die_007.png';
import Die008 from './Die_008.png';
import Die009 from './Die_009.png';
import Dizzy000 from './Dizzy_000.png';
import Dizzy001 from './Dizzy_001.png';
import Dizzy002 from './Dizzy_002.png';
import Duck000 from './Duck_000.png';
import Duck001 from './Duck_001.png';
import Duck002 from './Duck_002.png';
import Duck003 from './Duck_003.png';
import Hurt000 from './Hurt_000.png';
import Hurt001 from './Hurt_001.png';
import Hurt002 from './Hurt_002.png';
import Hurt003 from './Hurt_003.png';
import Idle001 from './Idle_001.png';
import Idle002 from './Idle_002.png';
import Idle003 from './Idle_003.png';
import Idle004 from './Idle_004.png';
import Idle005 from './Idle_005.png';
import Idle006 from './Idle_006.png';
import Idle007 from './Idle_007.png';
import Idle008 from './Idle_008.png';
import Idle009 from './Idle_009.png';
import Idle010 from './Idle_010.png';
import Idle011 from './Idle_011.png';
import Jetpack000 from './Jetpack_000.png';
import Jetpack001 from './Jetpack_001.png';
import Jetpack002 from './Jetpack_002.png';
import Jetpack003 from './Jetpack_003.png';
import Jetpack004 from './Jetpack_004.png';
import Jetpack005 from './Jetpack_005.png';
import Jump000 from './Jump_000.png';
import Jump001 from './Jump_001.png';
import Jump002 from './Jump_002.png';
import Jump003 from './Jump_003.png';
import Jump004 from './Jump_004.png';
import Jump005 from './Jump_005.png';
import Jump006 from './Jump_006.png';
import Jump007 from './Jump_007.png';
import Run001 from './Run_001.png';
import Run002 from './Run_002.png';
import Run003 from './Run_003.png';
import Run004 from './Run_004.png';
import Run005 from './Run_005.png';
import Run006 from './Run_006.png';
import Run007 from './Run_007.png';
import Walk001 from './Walk_001.png';
import Walk002 from './Walk_002.png';
import Walk003 from './Walk_003.png';
import Walk004 from './Walk_004.png';
import Walk005 from './Walk_005.png';
import Walk006 from './Walk_006.png';
import Walk007 from './Walk_007.png';
import Walk008 from './Walk_008.png';
import Walk009 from './Walk_009.png';

export const character_1 = {
  attack: { sprites: [Attack000, Attack001, Attack002, Attack003, Attack004, Attack005, Attack006, Attack007], loop: true },
  die: { sprites: [Die000, Die001, Die002, Die003, Die004, Die005, Die006, Die007, Die008, Die009], loop: false },
  dizzy: { sprites: [Dizzy000, Dizzy001, Dizzy002], loop: true },
  duck: { sprites: [Duck000, Duck001, Duck002, Duck003], loop: true },
  hurt: { sprites: [Hurt000, Hurt001, Hurt002, Hurt003], loop: true },
  idle: { sprites: [Idle001, Idle002, Idle003, Idle004, Idle005, Idle006, Idle007, Idle008, Idle009, Idle010, Idle011], loop: true },
  jetpack: { sprites: [Jetpack000, Jetpack001, Jetpack002, Jetpack003, Jetpack004, Jetpack005], loop: true },
  jump: { sprites: [Jump000, Jump001, Jump002, Jump003, Jump004, Jump005, Jump006, Jump007], loop: true },
  run: { sprites: [Run001, Run002, Run003, Run004, Run005, Run006, Run007], loop: true },
  walk: { sprites: [Walk001, Walk002, Walk003, Walk004, Walk005, Walk006, Walk007, Walk008, Walk009], loop: true },
};
