import styles from '../homePage.module.scss';
import { S3_PUBLIC_IMAGES } from '../../../../config';

  const coreValues1 = `${S3_PUBLIC_IMAGES}/website/coreValues1.png`
  const coreValues2 = `${S3_PUBLIC_IMAGES}/website/coreValues2.png`
  const coreValues3 = `${S3_PUBLIC_IMAGES}/website/coreValues3.png`

export const CoreValuesSection = (props) => {
  return (
    <div className={styles.coreValuesSection}>
      <h1 className={styles.header}>Our core values</h1>
      <div className={styles.values}>
        <img src={coreValues1} alt="Feel great" />
        <img src={coreValues2} alt="Spread Kindness" />
        <img src={coreValues3} alt="Build Resilience" />
      </div>
    </div>
  );
};
