import { S3_PUBLIC_IMAGES } from "../../../config";

import cssModule from "./centerBox.module.css";

const images = {
  ach_mobile_banner: `${S3_PUBLIC_IMAGES}/userSite/achievements_banner.png`,
};

const CenterBox = ({ boxContent, banner = true }) => {
  const mainContent = (
    <div className={cssModule.achievements_container}>
      {banner && (
        <div className={cssModule.ach_banner}>
          <img src={images.ach_mobile_banner} alt="" />
        </div>
      )}
      <div className={cssModule.page}>{boxContent}</div>
    </div>
  );
  return <>{mainContent}</>;
};

export default CenterBox;
