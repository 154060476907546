import {Vector} from './utils';
import {BaseAnimation} from '../animations/base';
import {OscillateAnimation} from '../animations/oscillate.animation';
import {CoinsAnimation} from '../animations/coins.animation';
import { StarsAnimation } from '../animations/stars.animation';
import { PulseAnimation } from '../animations/pulse.animation';

export const animationClasses: Record<string,any> = {
  "BaseAnimation":BaseAnimation,
  "OscillateAnimation": OscillateAnimation,
  "CoinsAnimation": CoinsAnimation,
  "StarsAnimation":StarsAnimation,
  "PulseAnimation":PulseAnimation
};

type ClassType<T> = new (...args: any[]) => T;

export type SpriteAnimation<T> = {
  type: ClassType<BaseAnimation<T>>;
  config: T;
  initialActive?: boolean
};

export type SpriteSheetConfig = {
  sprites: Array<string>;
  loop: boolean;
};

export interface SpriteConfig<States extends string = never> {
  id: string;
  refId?: string;
  position?: Vector;
  velocity?: Vector;
  width: number;
  height: number;
  bgImageUri: string | Record<States, SpriteSheetConfig>;
  initialState?: States;
  animations?: Record<string, SpriteAnimation<any>>;
}

export interface ObstacleConfig<States extends string = never> extends SpriteConfig<States> {
  pathImageUri?: string;
  isCompleted?: boolean;
  isDangerous?: boolean;
  requiredTools?: Array<string>;
}

export interface WorldConfig extends ObstacleConfig {
  scale: number;
  gravity: number;
}

export type CharacterState =
  'attack'
  | 'die'
  | 'dizzy'
  | 'duck'
  | 'hurt'
  | 'idle'
  | 'jetpack'
  | 'jump'
  | 'run'
  | 'walk';

export type CoinState = 'rotate' | 'idle';

export interface CharacterSpeedConfig {
  walk: number;
  jump: number;
  run: number;
  climb: number;
}

export interface CharacterConfig extends SpriteConfig<CharacterState> {
  speed: CharacterSpeedConfig;
  jumpDistance: number;
}

export interface CoinConfig extends SpriteConfig<CoinState> {
}

export interface ToolConfig {
  name: string;
  image: string;
  quantity: number;
}

export interface ToolConfigWithId extends ToolConfig {
  id: string;
}

export interface GameConfig {
  world: WorldConfig;
  character: CharacterConfig;
  coin: CoinConfig;
  obstacles: Array<ObstacleConfig>;
  tools: Record<string, ToolConfig>;
}
