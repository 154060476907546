export const backend_url = process.env.REACT_APP_BACKEND_URL;
export const signup_locked = 'true';
export const basePaths = {
    sign_up: "sign_up",
    login: "login",
    authentication: "checkAuth",
    admin: "admin",
    user: "user",
    logout: "logout",
    fitness: "fitness",
    support: "support",
    config: "config",
}
export const S3_PUBLIC_IMAGES = "https://thesupermeclub-client-assets.s3.us-east-1.amazonaws.com/public/images"

export const S3_PUBLIC = "https://thesupermeclub-client-assets.s3.us-east-1.amazonaws.com/public"

export const runMode = "demo";


// Ask ChatGPT to make questions for us!
// I am trying to make a quizz for my students to teach them about healthy habits and food. The questions can be multiple choice, fill in, or choosing from a drop down list. the topics that i am looking for are Nutrition, hydration, excercise, and kindess. can you give me create for me 10 questions for each question typeof; multiple choice, fill in, and choosing from a drop down list; and give it to me in this json format:
// {
//     question: "Why is water good for us?",
//     questionType: 'multiple-choice',
//     options: ['It keeps us hydrated','It taste good','Its fun to play with', 'It has no color'],
//     answer: 'It keeps us hydrated',
// }
// please make sure the questions are on the level of a childs knowledge below 5th grade
// thank you