import React from "react";

import styles from "./dialog.module.scss";
import { RenderableContent } from "../../../types";

type Props = {
  content: RenderableContent;
};

const Dialog = ({ content }: Props) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>{content}</div>
    </div>
  );
};

export default Dialog;
