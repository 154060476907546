import {SpriteConfig} from './game-config';
import p5 from 'p5';
import {GameContext} from './game-context';
import {Line, Vector} from './utils';
import {BaseAnimation} from '../animations/base';
import {Obstacle} from './obstacle';

export class Sprite<Config extends SpriteConfig<State> = SpriteConfig<any>, State extends string = never> {
  protected image: p5.Image | null = null;
  private sprites: Map<State, { sprites: Array<p5.Image>; loop: boolean }> = new Map();

  public p: p5 = this.context.p;
  public position: p5.Vector = this.p.createVector(this.config.position?.x, this.config.position?.y);
  public velocity = this.p.createVector(this.config.velocity?.x, this.config.velocity?.y);
  public opacity = 255;
  public opacityTint = this.p.color(255);
  public width = this.config.width;
  public height = this.config.height;
  public state = this.config.initialState;
  public stopSprite = false;

  protected animations: Map<string, BaseAnimation<any>> = new Map();

  private direction: 'left' | 'right' = 'right';

  constructor(
    public context: GameContext,
    protected config: Config
  ) {
    if (typeof this.config.bgImageUri === 'string') {
      this.p.loadImage(this.config.bgImageUri, (img) => {
        this.image = img;
        // if(this.config.id === 'world') {
        //   console.log(img);
        // }
      });
    } else {
      for (const [key, value] of Object.entries(this.config.bgImageUri)) {
        this.sprites.set(key as State, { sprites: [], loop: (value as any)?.loop });

        const urls = (value as any).sprites as Array<string>;

        urls.forEach((uri) => {
          this.p.loadImage(uri, (img) => {
            this.sprites.get(key as State)?.sprites.push(img);
          });
        });
      }
    }

    if (this.config.animations) {
      for (const [key, value] of Object.entries(this.config.animations)) {
        const animation = value.type;
        this.animations.set(key, new animation(value.config as any, this, Boolean(value.initialActive)));
      }
    }
  }

  public setup(): void {
    for (const [, animation] of Array.from(this.animations.entries())) {
      animation.initialize();
    }
  }

  public draw(): void {
    this.p.push();
    this.p.translate(this.position.x, this.position.y);
    this.position.add(this.velocity);
    this.p.tint(this.p.red(this.opacityTint), this.p.green(this.opacityTint), this.p.blue(this.opacityTint), this.opacity);

    this.updateDirection();

    this.drawImage();

    this.renderAnimations();

    this.p.pop();
  }

  private updateDirection() {
    if (this.velocity.x < 0) {
      this.direction = 'left';
    } else if (this.velocity.x > 0) {
      this.direction = 'right';
    }

    if (this.direction === 'left' && this.config.id === 'player') {
      this.p.translate(this.width, 0);
      this.p.scale(-1, 1);
    }
  }

  private renderAnimations() {
    this.p.push();
    for (const [, animation] of Array.from(this.animations.entries())) {
      if (animation.isActive()) {
        animation.run();
      }
    }
    this.p.pop();
  }

  public drawImage(x: number = 0, y: number = 0) {
    if (this.image) {
      this.p.image(this.image, x, y, this.width, this.height);
    }

    if (this.sprites.size > 0 && this.state) {
      const sheet = this.sprites.get(this.state);

      if (sheet?.sprites?.length) {
        let index = Math.floor(this.p.frameCount / 10) % sheet.sprites.length;

        if (!sheet.loop && (index === sheet.sprites.length - 1 || this.stopSprite)) {
          this.stopSprite = true;
          index = sheet.sprites.length - 1;
        }

        this.p.image(sheet.sprites[index], x, y, this.width, this.height);
      }
    }
  }

  public get center() {
    return this.position.copy().add(this.width / 2, this.height / 2);
  }

  public get boundingBox() {
    return {
      x: this.position.x,
      y: this.position.y,
      width: this.width,
      height: this.height,
    };
  }

  public nextBoundingBox(threshold = 1) {
    return {
      x: this.position.x + this.velocity.x * threshold,
      y: this.position.y + this.velocity.y * threshold,
      width: this.width,
      height: this.height,
    };
  }

  public getPerpendicular(obstacle: Obstacle): { line: Line; reset: Vector } {
    const line = { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } };
    const reset = { x: 0, y: 0 };

    if (obstacle.currentTool?.id === 'rope') {
      line.start.x = this.width / 4;
      line.start.y = this.height / 2;

      line.end.x = this.width * 0.75;
      line.end.y = this.height / 2;

      reset.x = this.width / 2;
      reset.y = this.height / 2;
    } else {
      const offset = Math.max(Math.abs(Math.ceil(this.velocity.y)), 10) / 2;
      line.start.x = this.width / 2;
      line.start.y = this.height - offset;

      line.end.x = this.width / 2;
      line.end.y = this.height + offset;

      reset.x = this.width / 2;
      reset.y = this.height;
    }

    return { line, reset: reset };
  }

  public startAnimation(id: string) {
    this.animations.get(id)?.start();
  }

  public pauseAnimation(id: string) {
    this.animations.get(id)?.pause();
  }

  public stopAnimation(id: string) {
    this.animations.get(id)?.stop();
  }

  public isAnimationActive(id: string): boolean{
    return this.animations.get(id)?.isActive() ?? false;
  }

  public lineDebug(line: Line) {
    this.p.push();
    this.p.noFill();
    this.p.translate(this.position.x, this.position.y);
    this.p.stroke(0, 0, 255);
    this.p.strokeWeight(5);
    this.p.line(line.start.x, line.start.y, line.end.x, line.end.y);
    this.p.pop();
  }
}
