import { useCallback } from "react";
import {
  Errors,
  ExpectedHeaders,
  FileProcessError,
  FileWithId,
  UploadSubmitDataKeys,
} from "../types";
import useProcessCSVHook from "../../../Hooks/useProcessCSVHook";
import { fileTypesConstructor } from "../../../../Util/utilsT";
import useProcessExcelHook from "../../../Hooks/useProcessExcelHook";
import { SetOpenDialog } from "../../../Pages/UsersPage/types";
import useDisplayErrors from "./useDisplayErrors";
import useCheckError from "./useCheckError";

type Props = {
  submitHandler: (data: Record<UploadSubmitDataKeys, any>) => void;
  expectedHeaders: ExpectedHeaders;
  setOpenDialog: SetOpenDialog;
};

const useProcessUploads = ({
  submitHandler,
  expectedHeaders,
  setOpenDialog,
}: Props) => {
  const processCSVHook = useProcessCSVHook();
  const processExcelHook = useProcessExcelHook(expectedHeaders);
  const checkError = useCheckError();
  const displayErrors = useDisplayErrors(setOpenDialog);

  const processUploads = useCallback(
    async (files: FileWithId[]) => {
      const submitData: Partial<Record<UploadSubmitDataKeys, any>> = {};
      const errors: FileProcessError[] = [];

      const handleError = (error: unknown, file: File) => {
        if (checkError(error)) {
          const errorObj = error as Errors;
          errors.push({ fileName: file.name, error: errorObj });
        } else {
          errors.push({
            fileName: file.name,
            error: [
              {
                errorTitle: "An Error has Occured",
                errorDetails: [
                  "Please check the uploaded file for any errors.",
                ],
              },
            ],
          });
        }
      };

      for (const file of files) {
        if (file.type === "text/csv") {
          try {
            const processedCsv = await processCSVHook(file);
            if (submitData.csv) {
              submitData.csv.push({
                name: file.name,
                id: file.id,
                content: processedCsv,
              });
            } else {
              submitData.csv = [
                { name: file.name, id: file.id, content: processedCsv },
              ];
            }
          } catch (error) {
            handleError(error, file);
          }
        } else if (fileTypesConstructor(["excel"]).includes(file.type)) {
          try {
            const processedExcel = await processExcelHook(file);
            if (submitData.excel) {
              submitData.excel.push({
                name: file.name,
                id: file.id,
                content: processedExcel,
              });
            } else {
              submitData.excel = [
                { name: file.name, id: file.id, content: processedExcel },
              ];
            }
          } catch (error) {
            handleError(error, file);
          }
        }
      }

      if (errors.length === 0) {
        submitHandler(submitData as Record<UploadSubmitDataKeys, any>);
      } else {
        displayErrors(errors);
      }
    },
    [checkError, processCSVHook, processExcelHook, submitHandler, displayErrors]
  );

  return processUploads;
};

export default useProcessUploads;
