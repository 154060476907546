import { useQuery } from '@tanstack/react-query'

import ApiService from "../../Services/apiServiceT";
import {
    ObstacleConfig,
    WorldConfig,
    CoinConfig,
    animationClasses
} from "../Pages/TreasureRoadPage/game/game-config";

import {
    character_1,
    coin as coinSprite
} from "../../assets/images/userSite/treasureRoad";

const images = require.context("../../assets/images/userSite", true);


const useUserTreasureRoad = () => {
    const apiService = new ApiService(true);

    const fetchUserData = async () => {

        let worldInfo = {
            treasureRoadPos: 0,
            worldId: "",
        };
        let gameInventory;
        let worldConfig: WorldConfig;
        let characterConfig;
        let obstacleConfig: Array<ObstacleConfig> = [];
        let completion;

        const [response, error] = await apiService.get({
            path: "/treasure_road",
            dataType: "user",
            data: {
                treasureRoadPos: -1,
            },
        });

        if (response) {
            const userData: any = response.data;

            // set world info
            worldInfo = {
                treasureRoadPos: userData.treasureRoadPos,
                worldId: userData.worldId
            };

            // Initilize world config
            worldConfig = userData.treasureRoad.world;
            worldConfig.id = "world";//userData.treasureRoad.world.refId;
            worldConfig.pathImageUri = images(worldConfig.pathImageUri!);
            worldConfig.bgImageUri = images(worldConfig.bgImageUri! as string);
            worldConfig.isCompleted = true; // Not sure why we need this and why it has to be set to true

            // Initilize character config
            characterConfig = userData.treasureRoad.character;
            characterConfig.id = "player";
            characterConfig.bgImageUri = character_1; // Need to make a generic way to create this sprite

            // Inilitize obstacles and world objects into one variable
            obstacleConfig = (userData.treasureRoad.worldObjects.concat(userData.treasureRoad.obstacles)).map((element: any) => {

                if (element.pathImageUri) element.pathImageUri = images(element.pathImageUri);
                if (element.animations) {

                    Object.entries(element.animations).forEach(([key, value]: [string, any]) => {
                        value.type = animationClasses[value.type];
                    });
                }

                element.id = element.name;//element.refId!;
                element.bgImageUri = images(element.bgImageUri! as string);

                return element;
            });

            // Initilize the completion object
            // Temp change untill we add a new object for the completion section
            completion = userData.treasureRoad.completion;
            completion.treasure.bgImageUri = images(completion.treasure.bgImageUri);
            Object.entries(completion.treasure.animations).forEach(([key, value]: [string, any]) => {
                value.type = animationClasses[value.type];
            });
            completion.treasure.id = "jackpot";
            completion.treasure.isCompleted = true;
            obstacleConfig.push(completion.treasure);

            // Initilize users inventory
            gameInventory = userData.gameInventory;
            gameInventory.structures = userData.gameInventory.structures?.reduce(
                (acc: any, cur: any) => {
                    // Try to get the image URL for the structure
                    let imageUrl;
                    try {
                        imageUrl = images(`./gameInventory/structures/${cur.name}.png`);
                    } catch (error) {
                        // If the image is not found (Webpack requires context might fail), log the error and skip
                        //console.log(`${cur.name}: Image not found`);
                        imageUrl = undefined;
                    }
                    if (imageUrl) {
                        // Added this check to avoid showing invalid tools like heart
                        acc[cur.name] = {
                            ...cur,
                            image: imageUrl,
                        };
                    }
                    return acc;
                },
                {}
            );
        }

        return {
            worldInfo,
            gameConfig: {
                world: {
                    ...worldConfig!,
                },
                character: {
                    ...characterConfig,
                },
                coin: {
                    id: 'coin',
                    position: { x: 0, y: 0 },
                    velocity: { x: 0, y: 0 },
                    width: 40,
                    height: 40,
                    initialState: 'rotate',
                    bgImageUri: coinSprite,
                } as CoinConfig,
                obstacles: obstacleConfig,
                tools: {
                    ...gameInventory.structures // this is not the best as we wont be able to expand out the game to use other inventory items
                }
            }
        };

    };

    return useQuery({
        queryKey: ['userTreasureRoad'],
        queryFn: fetchUserData,
        staleTime: 0,
    });


};

export default useUserTreasureRoad;
