import styles from "./contentBack.module.scss"

import PropTypes from "prop-types"

const ContentBack = ({ content, customClass}) => {

    const curveStyle = {
        // position: 'absolute',
        // top: '50%', 
        width: '100%',
        zIndex: '-1'
    };

    return (
        // <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        <div
            className={`${styles.container} ${customClass}`}
        >
            {/* Curved line pointing down */}

            <div className={styles.wrapper} >

                {content}

            </div>
            {/* Curved line pointing up */}

        </div>
        // </div>

    );
}

ContentBack.propTypes = {
  content: PropTypes.node.isRequired,
  customClass: PropTypes.object
}

export default ContentBack;