import React, { useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import p5 from "p5";
import { useQueryClient } from '@tanstack/react-query';
import {
  GameConfig,
  ToolConfigWithId,
} from "./game/game-config";
import { Game } from "./game/game";
import { Tools } from "./components/Tools";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider, useDrop } from "react-dnd";
import { DialogProps } from "./components/info-modal";
import { Controls } from "./components/Controls";
import DialogTemplate from "../../../Components/DialogTemplate";
import UserNavBar from "../../Components/userNavbar/UserNavBar";
import Loading from "../../../Components/loading/Loading";
import useUserTreasureRoad from "../../Hooks/useUserTreasureRoad";
import ApiService from "../../../Services/apiServiceT";
import { Inventory } from "./components/Inventory";


function createSketch(
  config: GameConfig,
  width: number,
  height: number,
  ref: React.MutableRefObject<Game | null>,
  onWorldCompleted: () => void
) {
  const worldCompleted = { triggered: false };
  return (p: p5) => {
    let game: Game;

    p.setup = () => {
      game = new Game(p, config, width, height);
      ref.current = game;
      game.setup();
    };

    p.draw = () => {
      game.draw();

      // Monitor `worldCompleted` and trigger the callback
      if (ref.current?.worldCompleted && !worldCompleted.triggered) {
        console.log(`worldComplete trigger hit! - ${worldCompleted.triggered}`);
        worldCompleted.triggered = true; // Prevent duplicate triggers
        onWorldCompleted(); // Trigger the callback
      }
    };
  };
}

const Canvas: React.FC<CanvasProps> = React.memo(
  ({ config, width, height, onWorldCompleted, ...props }) => {
    const ref = useRef(null);
    const gameInstance = useRef<Game | null>(null);
    const worldCompleted = useRef(false);

    const [, drop] = useDrop<ToolConfigWithId>({
      accept: Object.keys(config.tools),
      drop: (item, monitor) => {
        if (gameInstance.current) {
          const accept = gameInstance.current?.dropTool(item);

          if (!accept) {
            props.infoModal({
              mainText: "Wrong tool",
              subText: `The ${item.name} cannot be dropped here.`,
              closeHandler: () => props.infoModal(null),
            });
          } else {
            props.onToolDrop(item.id);
          }
        }
      },
      canDrop: (item) => {
        if (gameInstance.current) {
          gameInstance.current?.canDropTool(item);
        }
        return true;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    useEffect(() => {
      if (ref.current) {
        const myp5 = new p5(
          createSketch(config, width, height, gameInstance, onWorldCompleted),
          ref.current
        );
        return () => {
          myp5.remove();
        };
      }
    }, []);

    useEffect(() => {
      if (gameInstance.current) {
        gameInstance.current?.updateConfig(config);
      }
    }, [config]);

    useEffect(() => {
      if (gameInstance.current) {
        gameInstance.current?.resize(width, height);
      }
    }, [width, height]);

    return (
      <div className="relative overflow-hidden shadow-2xl z-10" ref={drop}>
        <div ref={ref} style={{ width, height, bottom: 0 }}></div>
      </div>
    );
  }
);

interface CanvasProps {
  config: GameConfig;
  width: number;
  height: number;
  infoModal: (props: DialogProps | null) => void;
  onToolDrop: (id: string) => void;
  onWorldCompleted: () => void
}

const TreasureRoadPage: React.FunctionComponent<Props> = (props) => {
  const [refreshCounter, setRefreshCounter] = useState(-1);
  const [infoModal, setInfoModal] = useState<DialogProps | null>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [direction, setDirection] = useState<string>(!isMobile ? 'left' : 'right');
  const [openInventory, setOpenInventory] = useState<boolean>(!isMobile ? true : false);

  const apiService = new ApiService(true);
  const queryClient = useQueryClient();
  const { data: userData, isLoading: isLoadingData, refetch } = useUserTreasureRoad();

  let gameConfig: GameConfig;

  const isTouchDevice = navigator.maxTouchPoints > 0;

  useEffect(() => {
    const resize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if (refreshCounter != -1) {
      window.location.reload();
      setRefreshCounter(prevKey => prevKey + 1);
    }
    else {
      setRefreshCounter(0);
    }
  }, [isTouchDevice])

  const onToolDrop = useCallback(async (id: string) => {
    // Need to subtract one from the users inventory

    // NOTE: Do we want to update the users db that he already used the tool and that the obstacle is complete?
    // if we dont the for the current world they will have to redo all the obstacles all the time but it could be fun but when they go to the new world those structures will still be available and we probably dont want that

    // Update the cache with the new data
    await queryClient.setQueryData(['userTreasureRoad'], (oldData: any) => {

      return {
        worldInfo: {
          ...oldData.worldInfo
        },
        gameConfig: {
          ...oldData.gameConfig,
          tools: {
            ...oldData.gameConfig.tools,
            [id]: {
              ...oldData.gameConfig.tools[id],
              quantity: oldData.gameConfig.tools[id].quantity - 1,
            },
          }
        }
      }
    });
  }, []);

  const onWorldCompleted = useCallback(async () => {
    //Show modal that they completed the World
    setInfoModal({
      mainText: "Wow! Great job completing the World!",
      subText: `Dont stop now! Are you ready for the next level!`,
      duration: "1000ms",
      button1Text: "Let's go!",
      onClickButton1: loadNextWorld,
      closeHandler: loadNextWorld,
    });

    const comUserData: { worldInfo: any, gameConfig: GameConfig } | undefined = queryClient.getQueryData(['userTreasureRoad']);

    const toolsLeft = Object.values(comUserData!.gameConfig.tools).map(tool => ({
      name: tool.name,
      quantity: tool.quantity,
    }));

    const [resp, error] = await apiService.post({
      path: "/treasure_road",
      dataType: "user",
      data: {
        treasureRoadPos: comUserData?.worldInfo.treasureRoadPos,
        completedWorldId: comUserData?.worldInfo.worldId,
        tools: toolsLeft,
        worldCompleted: true,
      }
    });

    if (resp?.data) {
      console.log(resp.data);
      // Update the cache with the new data
      // await queryClient.setQueryData(['userTreasureRoad'], oldData => {
      //     return {
      //         questTiles: qcResp.data.questTiles,
      //         statsConfig: qcResp.data.statsConfig,
      //         reward: qcResp.data.reward,
      //         questboardPos: qcResp.data.questboardPos,
      //         userData: oldData.userData
      //     }
      // });
      //refetch();
      setTimeout(() => {
        loadNextWorld();
      }, 10000);
    }
    else if (error) {
      setInfoModal({
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: () => setInfoModal(null),
      });
    }
  }, [userData]);

  const loadNextWorld = () => {
    setInfoModal(null);
    window.location.reload();
  }

  const openInventoryHandler = () => {
    const open = !isMobile ? true : !openInventory;
    setOpenInventory(open);
  }

  const directionHandler = (direction: 'left' | 'right') => {
    //const dir = !isMobile ? 'left' : direction;
    setDirection(direction);
  }

  if (!isLoadingData) {
    gameConfig = userData!.gameConfig;
  }

  return (
    <div>
      <nav className="">
        <UserNavBar />
      </nav>
      {isLoadingData ? (
        <Loading isLoading={isLoadingData} />
      ) : (
        <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend} options={isTouchDevice ? { enableMouseEvents: true } : undefined}>
          {infoModal && <DialogTemplate {...infoModal} />}
          <div className="fixed top-0 left-0 bottom-0 right-0 bg-brand flex justify-center items-center">
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-md z-10"></div>
            <Canvas
              config={gameConfig!}
              width={width}
              height={height}
              infoModal={setInfoModal}
              onToolDrop={onToolDrop}
              onWorldCompleted={onWorldCompleted}
            />
            <Inventory
              direction={direction}
              setOpenInventory={openInventoryHandler}
              hidden={openInventory}
            />
            <Tools
              tools={gameConfig!.tools}
              direction={direction}
              setOpenInventory={openInventoryHandler}
              hidden={!openInventory}
            />
            <Controls orientation={width > height ? "landscape" : "portrait"} setDirection={directionHandler} />
          </div>
        </DndProvider>
      )}
    </div>
  );
};

interface Props { }

export default TreasureRoadPage;
