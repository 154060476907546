import { React, useState, useEffect } from "react";

import ApiService from "../../../Services/apiService";
import Carousel from "../../../Components/carousel/Carousel";
import Question from "./components/question/Question";
import DialogTemplate from "../../../Components/DialogTemplate";
import UserPageTemplate from "../../Components/userPageTemplate/UserPageTemplate";

import cssModule from "./learnPage.module.scss"

const LearnPage = () => {
  const apiService = new ApiService(true);
  const [userLearnData, setUserLearnData] = useState({});
  const [startIndex, setStartIndex] = useState(0);
  const [dialog, setDialog] = useState(null);
  const [questions, setQuestions] = useState([]);
  // const [loadingDialogOpen,setLoadingDialogOpen] = useState(true);

  useEffect(() => {
    // setLoadingDialogOpen(true);
    const fetchData = async () => {
      const [response, error] = await apiService.get({
        path: "/daily_ques",
        dataType: "user",
      });

      if (response) {
        if (response.data.quAnswers) {
          const userData = {};
          response.data.quAnswers.forEach(function (qu) {
            userData[qu.question] = qu;
          });
          setUserLearnData(userData);
        }

        if (response.data.questions) {
          setQuestions(response.data.questions);
        }
      }

      // setLoadingDialogOpen(false);
    };

    fetchData();

    return () => {
      //handleLoadingDialogClose();
      // this now gets called when the component unmounts
    };
  }, []);

  const handleSubmit = async (index, answer) => {
    //Update the user info
    const newData = {
      learn: {
        question: questions[index].question,
        answer: answer,
        date: new Date(),
        rewardAmount: 1,
      },
      achievements: {},
    };
    userLearnData[newData.learn.question] = newData.learn;
    setUserLearnData(userLearnData);
    //DB update
    const [resp, error] = await apiService.patch({
      path: "/add_daily_ques",
      dataType: "user",
      data: newData,
    });

    if (!error) {
      if (questions[index].answer === '' || answer.replace(/[\W\s]/g, '') === questions[index].answer.replace(/[\W\s]/g, '')) {
        //Play Animation
        //playMcAnimation(top, left, data);
        setDialog({
          mainText: "You Got it!",
          subText: `Dont Stop now! Do the next Question!`,
          duration: "1000ms",
          closeHandler: () => setDialog(null),
        });
      }
      else {
        setDialog({
          mainText: "You Almost Got it!",
          subText: `The correct answer was ${questions[index].answer}!`,
          duration: "1000ms",
          closeHandler: () => setDialog(null),
        });
      }
      setTimeout(() => {
        setDialog(null);
      }, 2000);

      //Move to next question
      handleScroll("right");
    } else {
      setDialog({
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: () => setDialog(null),
      });
    }
  };

  const handleScroll = (direction) => {
    // if (direction === "left") {
    //   containerRef.current.scrollLeft -= single
    //     ? containerRef.current.clientWidth
    //     : scrollAmount;
    // } else if (direction === "right") {
    //   containerRef.current.scrollLeft += single
    //     ? containerRef.current.clientWidth
    //     : scrollAmount;
    // }
    if (direction === "left") {
      startIndex > 0
        ? setStartIndex(startIndex - 1)
        : setStartIndex(questions.length - 1);
    } else if (direction === "right") {
      startIndex < questions.length - 1
        ? setStartIndex(startIndex + 1)
        : setStartIndex(0);
    }
  };

  const carouselItems = questions.map((qu, index) => () => (
    <Question
      question={qu}
      index={index}
      handleSubmit={handleSubmit}
      defanswer={
        userLearnData.hasOwnProperty(qu.question)
          ? userLearnData[qu.question].answer
          : ""
      }
    />
  ));

  const mainContent = (
    <div
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "end",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        // marginTop: "5%",
        // border: '1px solid red'
      }}
    >
      <div className={cssModule.page} >
        <div
          className={cssModule.questionNumber}
        >
          {startIndex + 1} of {questions.length}
        </div>
        {/* <Scrollable direction="horizontal" components={carouselItems} /> */}
        <Carousel
          components={carouselItems}
          index={startIndex}
          handleScroll={handleScroll}
        />
      </div>
    </div>
  );

  return (
    <>
      {dialog && <DialogTemplate {...dialog} />}
      <UserPageTemplate pageContent={mainContent} footer footerClass={"scrollableFooter"} mainDivClass={"learnPageMainDiv"} />
    </>
  );
};

export default LearnPage;
