import PropTypes from "prop-types"

import { Header } from '../header/header'
import { SideMenu } from '../sideMenu/sideMenu'
import { Footer } from '../../../Components/footer/footer'
import { Statistics } from './components/statistics/statistics'

import styles from './adminTemplate.module.scss'

const AdminTemplate = ({ carousel, pageContent }) => {
  return (
    <div className={styles.adminPanel}>
    <Header
      mobileOnly={true}
      menu={<SideMenu/>}
    />
    <main className={styles.main}>
      <div className={styles.mainContent}>
        {carousel?.length > 0 && 
          (<section className={styles.section}>
          <div className={styles.sideMenu}>
            {/* <SideMenu/> */}
          </div>
          {carousel.length > 0 && <Statistics list={carousel} />}
            </section>)}
        <section className={styles.tables}>{pageContent}</section>
      </div>
    </main>
    <Footer />
  </div>
  )
}

AdminTemplate.propTypes = {
  carousel: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })),
  pageContent: PropTypes.node
}

AdminTemplate.defualtProps = {
  carousel: null
}

export default AdminTemplate